import React, { useCallback } from "react";

export const Button = React.memo(({
    onClick,
    itemData = null,
    children,
    ...props
}) => {

    const handleClick = useCallback((e) => {
        if (!onClick) return;
        onClick(e, itemData);
    }, [ itemData, onClick, ]);

    return (
        <button onClick={handleClick} {...props}>
            {children}
        </button>
    );
})