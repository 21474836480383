import { Stars } from '../stars';

import './style.scss';

export const ReviewExample = ({avatarSrc, avatarSrcSet, name, text, rating}) => (
  <div className={'subscription-store-reviews-example'}>
    <div className='meta'>
      <img className='avatar'
           src={avatarSrc}
           srcSet={avatarSrcSet}
           alt='avatar'/>
      <div className='container'>
        <div className='name'>
          {name}
        </div>
        <Stars className='stars' rating={rating}/>
      </div>
    </div>
    <div className='text'>
      {text}
    </div>
  </div>
);
