import { t, tm } from '@web-solutions/module-localization';

const getTitleText = (text, tKeyText, period, params = {}) => {
  if (text && typeof text === 'string' && text[0] === '_') {
    return t(text);
  }

  return tm(text, `core.plans.${tKeyText}.${period}`, params);
};

export const getSubTitleText = (text, period, isTrial, isIntroductory, days, price) => {
  let tKeyPeriod = period;

  if (isTrial) {
    tKeyPeriod = 'trial';
  }

  if (isIntroductory) {
    tKeyPeriod = 'introductory';
  }

  return getTitleText(text, 'items_sub_titles', tKeyPeriod, { days, price });
};

export const getProductTitle = (product) => {
  const { title, period } = product;

  return getTitleText(title, 'items_titles', period);
};