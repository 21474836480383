// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KPCw4fa628DJ4SPFsf3D{text-align:center;margin-bottom:10vh}.l11kbwXfZW6PKAg6w42T{color:#6a52ff}", "",{"version":3,"sources":["webpack://./../../packages/manage-subscription/src/terminate/components/footer/style.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA,sBACE,iBAAA,CACA,kBAAA,CAEA,sBACE,aCPW","sourcesContent":["$colorTheme: artpix;\n@import \"src/styles\";\r\n\r\n.footer {\r\n  text-align: center;\r\n  margin-bottom: 10vh;\r\n\r\n  &__link {\r\n    color: $terminateTitleColor;\r\n  }\r\n}","// colors\r\n$primaryColor: #6a52ff;\r\n$secondaryColor: #52dcaa;\r\n\r\n@if global-variable-exists(colorTheme) {\r\n  @if $colorTheme == \"aidating\" {\r\n    // colors\r\n    $primaryColor: #0E90D7;\r\n    $secondaryColor: #E76D8B;\r\n  }\r\n  @if $colorTheme == \"luvzy\" {\r\n    // colors\r\n    $primaryColor: #0d91d7;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "KPCw4fa628DJ4SPFsf3D",
	"footer__link": "l11kbwXfZW6PKAg6w42T"
};
export default ___CSS_LOADER_EXPORT___;
