/*

first_name: DEV
last_name: DEV
number: 4111111111111111
browser[color_depth]: 24
browser[java_enabled]: false
browser[language]: en-US
browser[referrer_url]: https://staging-api.joyhub.app/giantcrush-stage/?email=d.kazlouski%40pxlnd.com&purchased
browser[screen_height]: 667
browser[screen_width]: 375
browser[time_zone_offset]: -180
browser[user_agent]: Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1
month: 5
year: 2026
cvv: 111
version: 4.26.5
key: fra-NdJSGScTUQnkvDtXvTMvlX
deviceId: NWMxQiMCbsL5LDBi
sessionId: Egfvf7IwiZhDbkzd
instanceId: 5HEmweLGOOSznIgA

first_name: DEV
last_name: DEV
number: 4111111111111111
browser[color_depth]: 24
browser[java_enabled]: false
browser[language]: en-US
browser[referrer_url]: http://localhost:3000/giantcrush-stage?email=xx%40xx.xx&purchased
browser[screen_height]: 667
browser[screen_width]: 375
browser[time_zone_offset]: -180
browser[user_agent]: Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1
month: 5
year: 2026
cvv: 111
version: 4.26.5
key: ewr1-KSnvk6xteSAxoZwmlI0OFl
deviceId: eEhQZ0CIQsyrmnbL
sessionId: 9K5ECvwqzAAu9y6D
instanceId: vlDXEw5VBtqB0jCW

 */

export function getMagnusToken(): string {
  return process.env.REACT_APP_MAGNUS_TOKEN!;
  // return "e7tfwQLOzftfbRhYuWgb5oaOMA99NcF8";
}
