// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YgKdY_xIaSI4gHk08nWj{border:0;text-decoration:underline;color:#6a52ff}", "",{"version":3,"sources":["webpack://./../../packages/core/ui-elements/link-button/style.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA,sBACE,QAAA,CACA,yBAAA,CACA,aCLa","sourcesContent":["$colorTheme: artpix;\n@import 'src/styles';\n\n.root {\n  border: 0;\n  text-decoration: underline;\n  color: $primaryColor;\n}","// colors\r\n$primaryColor: #6a52ff;\r\n$secondaryColor: #52dcaa;\r\n\r\n@if global-variable-exists(colorTheme) {\r\n  @if $colorTheme == \"aidating\" {\r\n    // colors\r\n    $primaryColor: #0E90D7;\r\n    $secondaryColor: #E76D8B;\r\n  }\r\n  @if $colorTheme == \"luvzy\" {\r\n    // colors\r\n    $primaryColor: #0d91d7;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "YgKdY_xIaSI4gHk08nWj"
};
export default ___CSS_LOADER_EXPORT___;
