import { LString } from "@web-solutions/core/constants/remote-config"
import { Currency } from '@web-solutions/react-billing/constants';

export interface RemoteConfig {
  mngSubPlansPage: {
    title: LString,
    buttonText: LString,
  },
  mngSubMainPage: {
    currentPlanPrice: string,
    changePlanButtonText: LString,
  },
  mngSubReasonPage: {
    enabled: boolean
    changeMindOff: boolean
    changeMindSelected: boolean
  }
  mngSubBenefitsPage: {
    enabled: boolean,
    fixedButton: boolean,
    fixedLink: boolean,
  }
  mngSubCancelSuccessPage: {
    closeEnabled: boolean
  },
  mngSubOfferFreePeriodPage: {
    enabled: boolean,
    exclusiveOfferEnabled: boolean,
    fixedLink: boolean,
    fixedButton: boolean,
    USD: {
      subscriptionDescription: LString,
      productId: string,
    },
    EUR: {
      subscriptionDescription: LString,
      productId: string,
    },
    CAD: {
      subscriptionDescription: LString,
      productId: string,
    },
    AUD: {
      subscriptionDescription: LString,
      productId: string,
    },
  },
  mngSubFarewellOfferPage: {
    enabled: boolean,
    notActivePrice: string
    activePrice: string,
    price: number,
    discount: string,
    currency: Currency,
    acceptBtnTitle?: LString,
    declineBtnTitle?: LString,
  },
  mngSubOfferCheapProductPage: {
    enabled: boolean,
    backEnabled: boolean,
    discountAmount: number,
    subtitle: LString,
    USD: {
      price: LString,
      productId: string,
    },
    EUR: {
      price: LString,
      productId: string,
    },
    CAD: {
      price: LString,
      productId: string,
    },
    AUD: {
      price: LString,
      productId: string,
    },
  },
  mngSubTerminatePage: {
    fixedLink: boolean,
    fixedButton: boolean,
    enabled: boolean,
  },
  mngProgressPage: {
    enabled: boolean,
    fixedLink: boolean,
    fixedButton: boolean,
  }
}

export const INITIAL_CONFIG: RemoteConfig = {
  mngSubPlansPage: {
    title: '',
    buttonText: '',
  },
  mngSubMainPage: {
    currentPlanPrice: '{priceText}',
    changePlanButtonText: '',
  },
  mngSubReasonPage: {
    enabled: true,
    changeMindOff: false,
    changeMindSelected: false,
  },
  mngSubBenefitsPage: {
    enabled: false,
    fixedButton: false,
    fixedLink: false,
  },
  mngSubCancelSuccessPage: {
    closeEnabled: true,
  },
  mngSubOfferFreePeriodPage: {
    enabled: false,
    exclusiveOfferEnabled: true,
    fixedLink: false,
    fixedButton: false,
    USD: {
      subscriptionDescription: '',
      productId: '',
    },
    EUR: {
      subscriptionDescription: '',
      productId: '',
    },
    CAD: {
      subscriptionDescription: '',
      productId: '',
    },
    AUD: {
      subscriptionDescription: '',
      productId: '',
    },
  },
  mngSubFarewellOfferPage: {
    enabled: false,
    notActivePrice: '',
    activePrice: '',
    price: 1,
    discount: '',
    currency: 'USD',
  },
  mngSubOfferCheapProductPage: {
    enabled: false,
    backEnabled: true,
    discountAmount: 76,
    subtitle: '',
    USD: {
      price: '',
      productId: '',
    },
    EUR: {
      price: '',
      productId: '',
    },
    CAD: {
      price: '',
      productId: '',
    },
    AUD: {
      price: '',
      productId: '',
    },
  },
  mngSubTerminatePage: {
    fixedLink: false,
    fixedButton: false,
    enabled: false
  },
  mngProgressPage: {
    enabled: false,
    fixedLink: false,
    fixedButton: false,
  }
};
