import { createAction } from 'redux-actions';

import DeviceProps from '@magnus/react-native-device-props';

import Billing from '@web-solutions/module-billing';
import Analytics from '@web-solutions/module-analytics';
import { auth as authRequest, updateUserEmail } from '@web-solutions/base-app/api/auth';

import { reInit as reInitRemoteConfig } from '@web-solutions/core/store/remote-config/actions';

import { setApiAuth } from '@web-solutions/core/utils/network';
import { stringifyUrlParams } from '@web-solutions/core/utils/url-sync';

import * as TYPES from './types';

export const setAuthorized = createAction(TYPES.SET_AUTHORIZED);

const setEmail = createAction(TYPES.SET_EMAIL);
const setAge = createAction(TYPES.SET_AGE);
const setGender = createAction(TYPES.SET_GENDER);

export const processAge = (age) => (dispatch, getState) => {
  Analytics.setUserProperty('age', age);
  dispatch(setAge(age));
}

export const processGender = (gender) => async (dispatch, getState) => {
  Analytics.setUserProperty('gender', gender);
  await dispatch(reInitRemoteConfig());
  dispatch(setGender(gender));
}

export const auth = () => async (dispatch, getState) => {
  const { apiAuth } = getState().profile;

  const p = apiAuth
    ? Promise.resolve(apiAuth)
    : DeviceProps.getIDFM()
      .then((idfm) => authRequest(idfm));

  return p.then((res) => {
    setApiAuth(`${res.token_type} ${res.access_token}`);
    Billing.setApiAuth(`${res.token_type} ${res.access_token}`);
    dispatch(setAuthorized(res));
  });
}

export const processEmail = (email) => (dispatch, getState) => {
  Analytics.setUserProperty('email', email);

  Analytics.trackEvent('firebase_auth', 'update_email_submit', { email });
  updateUserEmail(email)
    .then(() => {
      Analytics.trackEvent('firebase_auth', 'update_email_success', { email });
    })
    .catch((error) => {
      Analytics.trackEvent('firebase_auth', 'update_email_error', { error: error?.message, code: error?.code, email });
    });

  dispatch(setEmail(email));
  stringifyUrlParams({ email })
}
