import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { t } from '@web-solutions/module-localization';

import { ButtonBehaviorType, ButtonPlacementType } from 'core/payment/constants';
import InjectedPaymentMethodButton from 'core/summary/injected-payment-method/components/button';
import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import TrialsLeft from 'core/payment/components/trials-left';

import { Button, Portal } from 'core/ui-elements';

import { useFixedButton } from 'core/hooks/use-fixed-button';
import { getButtonTitleByConfig } from 'core/utils/button-configurator';

import { useTimer } from '../../payment/hooks/use-timer';

import classes from './style.module.scss';

const tKey = 'core.payment_popup';

const FixedButton = ({
  activeProduct,
  buttonsConfigs,
  onlyFixed,
  onClick,
  id,
  onPayPalSubmit,
}) => {
  const buttonConfigs = buttonsConfigs[ButtonPlacementType.PLANS] || {};
  const fixedButtonConfigs = buttonsConfigs[ButtonPlacementType.FIXED] || {};
  const { isInjectedPaymentMethodOnPage } = useSelector(remoteConfigSelector);

  const title = getButtonTitleByConfig(buttonConfigs, 'core.get_my_plan');
  const fixedBtnTitle = getButtonTitleByConfig(fixedButtonConfigs, 'core.get_my_plan');
  const isFixedButtonModalBehavior = fixedButtonConfigs.behavior === ButtonBehaviorType.MODAL;

  const { fixed, isVisibleProducts } = useFixedButton({
    id,
    buttonsSelector: '.sub-static-btn',
    productSelector: '.product-item'
  });

  const timer = useTimer();

  const handleButtonClick = (e) => {
    Analytics.trackEvent('products_button', 'click');
    e.preventDefault();
    onClick(ButtonPlacementType.PLANS);
  };

  const handleFixedButtonClick = (e) => {
    Analytics.trackEvent('fixed_button', 'click');
    e.preventDefault();
    onClick(ButtonPlacementType.FIXED, isVisibleProducts.current);
  };

  return (
    <>
      {!onlyFixed && (
        <div className={classNames(classes.content, classes.buttonMargin)}>
          <TrialsLeft type='badge' />
          <Button
            onClick={onlyFixed ? handleFixedButtonClick : handleButtonClick}
            className={classNames('sub-static-btn', classes.button, {
              [classes.invisible]: fixed,
            })}
            titleClassName={classes.buttonTitleStyle}
            title={title}
            flare={!!buttonConfigs?.enabledFlareAnimation}
          />
          {
            isInjectedPaymentMethodOnPage && (
              <InjectedPaymentMethodButton
                activeProduct={activeProduct}
                onPayPalSubmit={onPayPalSubmit}
                onCardClick={onClick}
              />
            )
          }
        </div>
      )}
      <Portal>
        <div
          className={classNames(classes.fixed, {
            [classes.hidden]: !fixed,
          })}
        >
          {timer?.shown && !onlyFixed && (
            <div
              className={classes.discount}
              onClick={handleFixedButtonClick}
            >
              <div className={classes.timer}>
                <b>{timer?.minutes}</b><span className={classes.colon}>:</span><b>{timer?.seconds}</b>
              </div>
              {activeProduct?.discount && (
                <div>
                  <span className={classes.desc}>{t(`${tKey}.discount_fixed`, { discount: activeProduct?.discount })}</span>
                </div>
              )}
            </div>
          )}
          <div className={classes.content}>
            <Button
              onClick={handleFixedButtonClick}
              className={classNames(classes.button, 'sub-fixed-btn')}
              titleClassName={classes.buttonTitleStyle}
              title={fixedBtnTitle}
              flare={!!fixedButtonConfigs?.enabledFlareAnimation}
            />
            {
              isInjectedPaymentMethodOnPage && isFixedButtonModalBehavior && (
                <InjectedPaymentMethodButton
                  activeProduct={activeProduct}
                  onPayPalSubmit={onPayPalSubmit}
                  onCardClick={onClick}
                />
              )
            }
          </div>
        </div>
      </Portal>
    </>
  );
};

FixedButton.propTypes = {
  activeProduct: PropTypes.object.isRequired,
  buttonsConfigs: PropTypes.object.isRequired,
  onlyFixed: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string,
  onApplePaySubmit: PropTypes.func,
  onPayPalSubmit: PropTypes.func,
};

FixedButton.defaultProps = {
  id: '',
};

export default FixedButton;
