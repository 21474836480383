// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OIfFESOAcx5vDqKmCLnw{width:100vw;height:100vh;top:0;left:0}.ZDC_FY3qDoP9HQrUJG08{width:100%;height:100%}", "",{"version":3,"sources":["webpack://./../../packages/core/containers/verify-3ds/style.module.scss"],"names":[],"mappings":"AAGA,sBACE,WAAA,CACA,YAAA,CACA,KAAA,CACA,MAAA,CAGF,sBACE,UAAA,CACA,WAAA","sourcesContent":["$colorTheme: artpix;\n@import \"src/styles\";\n\n.iframe_modal {\n  width: 100vw;\n  height: 100vh;\n  top: 0;\n  left: 0;\n}\n\n.iframe {\n  width: 100%;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframe_modal": "OIfFESOAcx5vDqKmCLnw",
	"iframe": "ZDC_FY3qDoP9HQrUJG08"
};
export default ___CSS_LOADER_EXPORT___;
