import React from 'react';
import { useSelector } from 'react-redux';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';

import './style.scss';

export const SubscriptionFooter = () => {
  const {policyLinks, address} = useSelector(remoteConfigSelector);
  
  return (
    <div className='subscription-footer'>
      <div className='container'>
        <div className='contact-title'>
          Contacts
        </div>
        <div className='contact-address'>
          {address}
        </div>
        <div className='links'>
          <a href={policyLinks.privacy} target='_blank'>Privacy Policy</a> |
          <a href={policyLinks.termsOfUse} target='_blank'>Terms of Use</a> |
          <a href={policyLinks.billingTerms} target='_blank'>Billing Terms</a>
        </div>
        <div className='links'>
          <a href={policyLinks.moneyBack} target='_blank'>Money-Back Policy</a>
        </div>
      </div>
    </div>
  );
};
