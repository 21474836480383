import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface Props {
  children: React.ReactNode;
  className: string;
}

const Section = React.forwardRef<HTMLElement, Props>(({ children, className, ...rest }, ref) => (
  <section ref={ref} className={classNames(classes.wrap, className)} {...rest}>
    {children}
  </section>
));

export default Section;
