import React from 'react';

import { createSrcSet, EXTENSION } from 'src/utils/create-src-set';

import './style.scss';

export const AppInfo = () => (
  <div className={'subscription-app-info'}>
    <img
      src={`${process.env.REACT_APP_PUBLIC_URL}/assets/UI/AppIcon.png`}
      srcSet={createSrcSet(`${process.env.REACT_APP_PUBLIC_URL}/assets/UI/AppIcon`, EXTENSION.PNG)}
      className='subscription-app-icon'
      alt='app icon'/>
  </div>
);
