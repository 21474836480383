import "./style.css";

import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stepBack } from "src/store/progress/actions";

export const BackButton = React.memo(() => {
  const dispatch = useDispatch();
  const currentStepIndex = useSelector(state => state.progress.currentStepIndex);

  const handleClick = useCallback(() => {
    dispatch(stepBack());
  }, []);

  return (
      <div className={`back-btn ${currentStepIndex === 0 ? 'hidden' : ''}`} onClick={handleClick}>
        <img className='back-btn-image' src={`${process.env.REACT_APP_PUBLIC_URL}/assets/UI/Arrow.svg`} />
      </div>
  );
})
