// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yD_zfzGl1lgfLZqw2Dpp{padding:16px;width:100%;min-height:100%;display:flex;flex-direction:column;align-items:center}.yD_zfzGl1lgfLZqw2Dpp h1{margin-bottom:32px}.yD_zfzGl1lgfLZqw2Dpp .QtSNnymOdDKlsjcOBmYS{margin-bottom:16px}", "",{"version":3,"sources":["webpack://./../../packages/manage-subscription/src/manage/screens/main/style.module.scss"],"names":[],"mappings":"AAGA,sBACE,YAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CAEA,yBACE,kBAAA,CAGF,4CACE,kBAAA","sourcesContent":["$colorTheme: artpix;\n@import \"src/styles\";\r\n\r\n.wrap {\r\n  padding: 16px;\r\n  width: 100%;\r\n  min-height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n\r\n  h1 {\r\n    margin-bottom: 32px;\r\n  }\r\n\r\n  .btn {\r\n    margin-bottom: 16px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "yD_zfzGl1lgfLZqw2Dpp",
	"btn": "QtSNnymOdDKlsjcOBmYS"
};
export default ___CSS_LOADER_EXPORT___;
