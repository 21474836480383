import "./style.css";

import React from "react";
import { BackButton } from "src/components/connected/back-button";

export const ProgressBar = React.memo(({
                                         current = 1,
                                         max = 10,
                                         hidden = false,
                                       }) => {


  return (
      <div className={`progress-bar-container ${hidden ? 'hidden' : ''}`}>
          <BackButton />
          <div className='progress-bar'>
              <div className='progress-bar-filled' style={{width: `${current / max * 100}%`}}/>
          </div>
          <div className='progress-bar-text'>
              <span className='highlighted'>{current}</span>/{max}
          </div>
      </div>
  );
})