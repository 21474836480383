import dayjs from 'dayjs';

import { Period, Product, ProductInfo } from '../constants';

export const getPreparedWeekPrice = (period: Period, amount: number): string => {
  let result = amount;

  switch (period) {
    case 'P2W':
      result = amount / 2;
      break;
    case 'P3W':
      result = amount / 3;
      break;
    case 'P1M':
      result = amount / 4;
      break;
    case 'P2M':
      result = amount / 8;
      break;
    case 'P3M':
      result = amount / 13;
      break;
    case 'P6M':
      result = amount / 26;
      break;
    case 'P1Y':
      result = amount / 52;
      break;
    default:
      result = Number(amount);
  }

  return String(+result.toFixed(2));
};

export const getPreparedDayPrice = (period: Period, amount: number): string => {
  let result = amount;

  switch (period) {
    case 'P1W':
      result = amount / 7;
      break;
    case 'P2W':
      result = amount / 7 / 2;
      break;
    case 'P3W':
      result = amount / 7 / 3;
      break;
    case 'P1M':
      result = amount / 30;
      break;
    case 'P2M':
      result = amount / 30 / 2;
      break;
    case 'P3M':
      result = amount / 30 / 3;
      break;
    case 'P6M':
      result = amount / 30 / 6;
      break;
    case 'P1Y':
      result = amount / 365;
      break;
    default:
      result = Number(amount);
  }

  return String(+result.toFixed(2));
};

export const getCurrencySymbols = (currency: string) => {
  let currencySymbol: string = currency;
  let currencySuffix = '';
  if (currency === 'AUD' || currency === "CAD") {
    currencySuffix = ` ${currency}`;
  }

  switch (currency?.toLowerCase?.()) {
    case 'usd':
      currencySymbol = '$';
      break;
    case 'eur':
      currencySymbol = '€';
      break;
    case 'aud':
      currencySymbol = '$';
      break;
    case 'cad':
      currencySymbol = '$';
      break;
    default:
      break;
  }

  return { currencySymbol, currencySuffix }
}

const mappingPricesToProducts = (products: any[], prices: Product[]): ProductInfo[] => {
  return products.reduce((pds, item) => {
    const price = prices.find(({ id }) => id === item.id) as ProductInfo;
    if (price) {
      const { amount, currency, trial_period_days, trial_price_amount } = price;

      const { currencySymbol, currencySuffix } = getCurrencySymbols(currency);

      const period = price.period || item.period;
      const weekAmount = getPreparedWeekPrice(period, +amount);
      const dayAmount = getPreparedDayPrice(period, +amount);

      price.trialPeriodDays = +trial_period_days;
      price.trialPeriodPrice = `${currencySymbol}${trial_price_amount}${currencySuffix}`;

      price.trialPeriodPriceValue = +trial_price_amount;
      price.isTrial = price.trialPeriodDays > 0;

      price.isIntroductory = price.trialPeriodPriceValue > 0;
      price.priceText = `${currencySymbol}${amount}${currencySuffix}`;
      price.weekPrice = `${currencySymbol}${weekAmount}${currencySuffix}`;
      price.dayPrice = `${currencySymbol}${dayAmount}${currencySuffix}`;
      price.weekPriceAmount = weekAmount;
      price.dayPriceAmount = dayAmount;

      const expireAt = dayjs().add(dayjs.duration(period)).add(dayjs.duration(price.trialPeriodDays, 'd'));
      const introExpireAt = dayjs().add(dayjs.duration(price.trialPeriodDays, 'd'));

      pds.push({
        ...item,
        ...price,
        expireDate: expireAt.format('LL'),
        introExpireDate: introExpireAt.format('LL'),
        expireAt: dayjs(expireAt).unix(),
      });
    }

    return pds;
  }, []);
};

export default mappingPricesToProducts;
