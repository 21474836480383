import React from 'react';
import { useSelector } from 'react-redux';

import { SubscriptionScreen as SubscriptionScreenV1 } from 'src/screens/subscription/v1';
import { SubscriptionScreen as SubscriptionScreenV2 } from 'src/screens/subscription/v2';

import { SubscriptionScreenVersion } from 'src/screens/subscription/version';

const MAPPED_SCREEN_COMPONENT = {
  [SubscriptionScreenVersion.V1]: SubscriptionScreenV1,
  [SubscriptionScreenVersion.V2]: SubscriptionScreenV2,
};

export const SubscriptionScreen = React.memo(() => {
  const screenVersion = useSelector(state => state.remoteConfig.subscriptionScreenVersion);
  const ScreenComponent = MAPPED_SCREEN_COMPONENT[screenVersion];

  return <ScreenComponent/>;
});
