import UAParser from 'ua-parser-js';

import Analytics from '@web-solutions/module-analytics';

export enum REDIRECT_TO_CHROME {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  ON_CLICK = 'onclick',
  SUCCESS = 'success',
  FINAL = 'final',
}

export function doRedirectToChrome(mode: REDIRECT_TO_CHROME, link?: string) {
  const r = new UAParser().getResult();
  if (['Facebook', 'Instagram'].includes(r?.browser?.name || '')) {
    if (r?.os?.name === 'Android') {
      Analytics.trackEvent('redirect', 'to_chrome', { mode });
      window.location.href = `intent:${link || window.location.href}#Intent;end`;
    } else if (r?.os?.name === 'iOS') {
      Analytics.trackEvent('redirect', 'to_chrome', { mode });
      const l = "googlechrome-x-callback://x-callback-url/open/?url=" + encodeURIComponent(link || window.location.href);
      if (mode === REDIRECT_TO_CHROME.ENABLED) {
        window.location.href = l;
      } else {
        window.open(l);
      }
    }
  }
}
