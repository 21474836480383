import {
  COMPLETE_STEP,
  STEP_BACK,
  UPDATE_STEP,
  SET_PURCHASED,
} from './types';

export const initialState = {
  currentStepIndex: 0,
  dataKeysStack: [],
  data: {},
  completed: false,
  purchased: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPLETE_STEP:
      let nextStep = state.currentStepIndex + 1;
      let completed = false;

      if (nextStep > action.payload.data.totalSteps)
      {
        nextStep--;
      }

      completed = nextStep === action.payload.data.totalSteps;

      return {
        ...state,
        currentStepIndex: nextStep,
        dataKeysStack: [ ...state.dataKeysStack, action.payload.key, ],
        data: {
          ...state.data,
          [action.payload.key]: action.payload.data.stepData,
        },
        completed,
      };

    case UPDATE_STEP:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.data,
        },
      }; 

    case STEP_BACK:
      if (state.currentStepIndex === 0) 
        return state;

      const keyToDelete = state.dataKeysStack.pop();
      delete state.data[keyToDelete];

      return {
        ...state,
        currentStepIndex: (state.currentStepIndex - 1),
        dataKeysStack: [ ...state.dataKeysStack, ],
        data: { ...state.data, },
        completed: false,
      };

    case SET_PURCHASED:
      return {
        ...state,
        purchased: action.payload,
      }

    default:
      return state;
  }
};

export default reducer;
