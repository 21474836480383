import _mapValues from 'lodash/mapValues';
import queryString from 'query-string';

import DeviceProps from '@magnus/react-native-device-props';
import UserProps from '@magnus/react-native-user-props';
import EvTruck from '@magnus/react-native-evtruck';

import Web2App from '@wowmaking/web2app';

export type AppLinks = {
  ADJUST_LINK?: string;
  ADJUST_TOKEN?: string;
  WEB_APP_LINK?: string;
  IOS_APP_ID?: string;
  ANDROID_APP_ID?: string;
  APP_SCHEME?: string,
}

let IDFM: string | undefined;
let appLinks: AppLinks | undefined;

const AttributionModule = {
  init(links: AppLinks) {
    appLinks = links;

    DeviceProps.getIDFM().then((idfm) => {
      IDFM = idfm;
    });

    const params = queryString.parse(window.location.search);

    const attribution = _mapValues({
      utm_source: params.utm_source || '',
      campaign_name: params.campaign_name || '',
      campaign_id: params.campaign_id || '',
      adset_name: params.adset_name || '',
      adset_id: params.adset_id || '',
      ad_name: params.ad_name || '',
      ad_id: params.ad_id || '',
      http_referer: window.document.referrer || '',
    }, String);

    EvTruck.trackWebAttribution(attribution);

    Web2App.init({
      onChange: (system, p) => {
        // оборачиваем в массив, в будущем сможем поддерживать несколько пикселей на сайте и тп
        EvTruck.trackWebMeasurements({
          external_id: Web2App.getAttribution().web_external_id,
          [system]: [p],
        });
      },
    });

    if (appLinks.ADJUST_LINK && appLinks.ADJUST_TOKEN) {
      return Web2App.trackAdjustImpression(appLinks.ADJUST_TOKEN);
    }
  },

  getLinkTarget() {
    return appLinks?.WEB_APP_LINK ? 'web' : undefined;
  },

  async getLinkToApp() {
    const source = 'sub';

    if (!appLinks) {
      return Promise.reject();
    }

    const up = await UserProps.fetch() as { utm_source: string, campaign_id: string, };

    if (appLinks.WEB_APP_LINK) {
      return Promise.resolve(
        queryString.stringifyUrl({
          url: appLinks.WEB_APP_LINK,
          query: {
            idfm: IDFM,
            source,
            utm_source: up.utm_source,
            campaign_id: up.campaign_id,
          },
        })
      );
    } else if (appLinks.ADJUST_LINK && appLinks.ADJUST_TOKEN) {
      return Web2App.createAdjustLink(appLinks.ADJUST_LINK, appLinks.ADJUST_TOKEN, {
        uuid: IDFM,
        source,
        adj_deep_link: appLinks.APP_SCHEME ? Web2App.createAdjustLink(appLinks.APP_SCHEME + 'p', undefined, { uuid: IDFM, source, }) : undefined,
        adj_redirect_android: appLinks.ANDROID_APP_ID ? `https://play.google.com/store/apps/details?id=${appLinks.ANDROID_APP_ID}&referrer=adjust_reftag={reftag}%26utm_source%3D{network_name}%26utm_campaign%3D{campaign_name}%26utm_content%3D{adgroup_name}%26utm_term%3D{creative_name}` : undefined,
      });

    } else {
      return Promise.reject(Error('Cannot create app link'));
    }
  },
};

export default AttributionModule;
