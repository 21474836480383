import { createAction } from 'redux-actions';

import Attribution from '@web-solutions/module-attribution';

import Analytics from '@web-solutions/module-analytics';

import { remoteConfigSelector } from '@web-solutions/core/store/remote-config/selectors';
import { REDIRECT_TO_CHROME, doRedirectToChrome } from '@web-solutions/core/utils/redirect-to-chrome';

import { SET_LOADED, SET_PENDING, SET_APP_LINK } from './types';

export const setLoaded = createAction(SET_LOADED);
export const setPending = createAction(SET_PENDING);
const setAppLink = createAction(SET_APP_LINK);

export const initAppLink = () => async (dispatch) => {
  const appLink = await Attribution.getLinkToApp();
  dispatch(setAppLink(appLink));
};

export const downloadApp = () => async (dispatch, getState) => {
  const state = getState();
  const { app: { appLink } } = state;
  const { redirectToChrome } = remoteConfigSelector(state);
  Analytics.trackEvent('install_app', 'click');
  window.location.href = appLink;
  if (redirectToChrome === REDIRECT_TO_CHROME.FINAL) {
    doRedirectToChrome(redirectToChrome, appLink);
  }
};
