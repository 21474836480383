// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f6PlkB1KP0bxM3UDFANk{width:100%;margin-top:16px}", "",{"version":3,"sources":["webpack://./../../packages/core/payment/components/paypal/paypal-button/style.module.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CACA,eAAA","sourcesContent":["$colorTheme: artpix;\n@import 'src/styles';\r\n\r\n.container {\r\n  width: 100%;\r\n  margin-top: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "f6PlkB1KP0bxM3UDFANk"
};
export default ___CSS_LOADER_EXPORT___;
