// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QMqvgBJljHwjq4jD1f8c{padding:16px}.QMqvgBJljHwjq4jD1f8c .tFARDAbloP8YaBQ3S92J{margin-bottom:24px;padding-left:20px}.QMqvgBJljHwjq4jD1f8c .qjbClPYDEdDufZSm8tVy{color:#000;margin-bottom:24px}.QMqvgBJljHwjq4jD1f8c footer{display:flex;flex-direction:column;align-items:center}.QMqvgBJljHwjq4jD1f8c footer .wBV__supvUWGyojW4AZz{margin-top:50px}", "",{"version":3,"sources":["webpack://./../../packages/manage-subscription/src/manage/screens/benefits/style.module.scss","webpack://./../../packages/manage-subscription/src/manage/styles/_theme.scss"],"names":[],"mappings":"AAGA,sBACE,YAAA,CAEA,4CACE,kBAAA,CACA,iBAAA,CAGF,4CACE,UCZc,CDad,kBAAA,CAGF,6BACE,YAAA,CACA,qBAAA,CACA,kBAAA,CAEA,mDACE,eAAA","sourcesContent":["$colorTheme: artpix;\n@import \"src/styles\";\n\n.wrap {\n  padding: 16px;\n\n  .title {\n    margin-bottom: 24px;\n    padding-left: 20px;\n  }\n\n  .subtitle {\n    color: $manageMainColor;\n    margin-bottom: 24px;\n  }\n\n  footer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    .bottom_button {\n      margin-top: 50px;\n    }\n\n\n  }\n}","$manageMainColor: #000000;\r\n$manageAccentColor: $primaryColor;\r\n\r\n$manageBg: none;\r\n$manageTextareaBorder: $primaryColor;\r\n$manageTextareaBg: $manageMainColor;\r\n$manageCheckmark: $primaryColor;\r\n$manageCheckboxBg: rgba($primaryColor, 0.7);\r\n$manageCheckboxChecked: $manageAccentColor;\r\n$manageModalBg: $white;\r\n$manageModalCloseIcon: #000000;\r\n\r\n$manageCurrentPlanBg: white;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "QMqvgBJljHwjq4jD1f8c",
	"title": "tFARDAbloP8YaBQ3S92J",
	"subtitle": "qjbClPYDEdDufZSm8tVy",
	"bottom_button": "wBV__supvUWGyojW4AZz"
};
export default ___CSS_LOADER_EXPORT___;
