// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{background:none}.G4K8eWqvLwdtvaogWg3Q{max-width:400px;margin:0 auto;position:relative}", "",{"version":3,"sources":["webpack://./../../packages/manage-subscription/src/manage/style.module.scss","webpack://./../../packages/manage-subscription/src/manage/styles/_theme.scss"],"names":[],"mappings":"AAGA,KACE,eCDS,CDIX,sBACE,eAAA,CACA,aAAA,CACA,iBAAA","sourcesContent":["$colorTheme: artpix;\n@import \"src/styles\";\r\n\r\nbody {\r\n  background: $manageBg;\r\n}\r\n\r\n.wrapper {\r\n  max-width: 400px;\r\n  margin: 0 auto;\r\n  position: relative;\r\n}\r\n","$manageMainColor: #000000;\r\n$manageAccentColor: $primaryColor;\r\n\r\n$manageBg: none;\r\n$manageTextareaBorder: $primaryColor;\r\n$manageTextareaBg: $manageMainColor;\r\n$manageCheckmark: $primaryColor;\r\n$manageCheckboxBg: rgba($primaryColor, 0.7);\r\n$manageCheckboxChecked: $manageAccentColor;\r\n$manageModalBg: $white;\r\n$manageModalCloseIcon: #000000;\r\n\r\n$manageCurrentPlanBg: white;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "G4K8eWqvLwdtvaogWg3Q"
};
export default ___CSS_LOADER_EXPORT___;
