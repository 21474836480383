import './style.css';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Analytics from '@web-solutions/module-analytics';
import { completeStepAndNavigate } from 'src/store/progress/actions';

export const QuizOptions = React.memo(({
                                         title = '',
                                         answers = {},
                                         stepKey,
                                         onContinue,
                                         animStartIndex = 0,
                                         animClassOpacity = '',
                                         animClassSlide = '',
                                       }) => {

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const navigate = () => {

    if (typeof onContinue === 'function') {
      setTimeout(() => {
        onContinue().then(() => {
          Analytics.trackEvent('step', 'completed', {
            id: stepKey
          });
          dispatch(completeStepAndNavigate(stepKey, 1));
        });
      }, 500);
      return;
    }

    setTimeout(() => {
      Analytics.trackEvent('step', 'completed', {
        id: stepKey
      });
      dispatch(completeStepAndNavigate(stepKey, 1));
    }, 500);
  };

  const [index, setIndex] = useState(-1);

  return (
    <div className='quiz-options'>
      <div className='quiz-options-container'>
        {title.length > 0 ? (
          <div className={`title ${animClassOpacity} animation-delay-${animStartIndex}`}>
            {title}
          </div>
        ) : undefined}
        <ul className={`${animClassSlide} animation-delay-${animStartIndex + 1}`}>
          {Object.keys(answers).map((answerKey, answerIndex) => (
            <li
              key={answerKey}
              className={`${index === answerIndex ? ' active' : ''}`}
              onClick={() => {
                if (disabled) {
                  return;
                }
                setIndex(answerIndex);
                setDisabled(true);
                navigate();
              }}>
              {answers[answerKey]}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});
