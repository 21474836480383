import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface Props{
  children: string | React.ReactNode;
  className?: string;
}

const Text: React.FC<Props> = ({ children, className }) => {
  const textStyle = classNames([classes.text, className]);
  return <p className={textStyle}>{children}</p>;
};

export default Text;
