import React from 'react';

import { createSrcSet, EXTENSION } from 'src/utils/create-src-set';

import { BlurContainer } from '../blur-container';

import './style.scss';

import { ReviewExample } from './components/review-example';
import { ReviewRating } from './components/review-rating';

export const StoreReviews = ({gradientTop = true, beforeFooter = false}) => (
  <div className='subscription-store-reviews'>
    <BlurContainer gradientTo={gradientTop} beforeFooter={beforeFooter}/>
    <div className='stats'>
      <div className='title'>
        More than 5 millions
        <br/>
        of happy users!
      </div>
      <div className='ratings'>
        <ReviewRating
          imageClassName='apple'
          storeSrc={`${process.env.REACT_APP_PUBLIC_URL}/assets/UI/AppStoreIcon.png`}
          storeSrcSet={createSrcSet(`${process.env.REACT_APP_PUBLIC_URL}/assets/UI/AppStoreIcon`, EXTENSION.PNG)}
          rating='4,053'
        />
        <ReviewRating
          imageClassName='google'
          storeSrc={`${process.env.REACT_APP_PUBLIC_URL}/assets/UI/GooglePlayIcon.png`}
          storeSrcSet={createSrcSet(`${process.env.REACT_APP_PUBLIC_URL}/assets/UI/GooglePlayIcon`, EXTENSION.PNG)}
          rating='6,929'
        />
      </div>
    </div>
    <div className='examples'>
      <ReviewExample
        avatarSrc={`${process.env.REACT_APP_PUBLIC_URL}/assets/SubscriptionContent/ReviewAuthorAvatar1.png`}
        avatarSrcSet={createSrcSet(`${process.env.REACT_APP_PUBLIC_URL}/assets/SubscriptionContent/ReviewAuthorAvatar1`, EXTENSION.PNG)}
        name={'Ian White'}
        text={`This game is an absolute blast! The combination of hilarious characters, satisfying gameplay mechanics, and a
          wide range of weapons make it a joy to play.`}
        rating={5}
      />
      <ReviewExample
        avatarSrc={`${process.env.REACT_APP_PUBLIC_URL}/assets/SubscriptionContent/ReviewAuthorAvatar2.png`}
        avatarSrcSet={createSrcSet(`${process.env.REACT_APP_PUBLIC_URL}/assets/SubscriptionContent/ReviewAuthorAvatar2`, EXTENSION.PNG)}
        name={'Susan Nelson'}
        text={`I can’t get enough of Kick Giant! The simple controls and addictive gameplay keep me coming back for more.
          Plus, the variety of weapons and upgrades make each level feel fresh and exciting. Don’t miss out on the
          action—download it today!`}
        rating={4.5}
      />
      <ReviewExample
        avatarSrc={`${process.env.REACT_APP_PUBLIC_URL}/assets/SubscriptionContent/ReviewAuthorAvatar3.png`}
        avatarSrcSet={createSrcSet(`${process.env.REACT_APP_PUBLIC_URL}/assets/SubscriptionContent/ReviewAuthorAvatar3`, EXTENSION.PNG)}
        name={'Aaron Clark'}
        text={`I’ve never had so much fun smashing giants! The variety of weapons and the satisfaction of defeating each
          level keep me coming back for more. Plus, the game’s humorous tone adds an extra layer of enjoyment to the
          experience.`}
        rating={5}
      />
    </div>
  </div>
);
