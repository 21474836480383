import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectProducts } from '@web-solutions/core/store/billing/selectors';
import { PaymentProcessor } from '@web-solutions/core/payment';

import { setPurchasedStatus } from 'src/store/progress/actions';
import { openAppLink } from 'src/store/app/actions';
import Plans from 'src/components/overrides/plans';

import { AppInfo } from '../components/app-info';
import { QuizScreen } from '../components/quiz-screen';
import { StoreReviews } from '../components/store-reviews';
import { SubscriptionForm } from '../components/subscription-form';
import { SubscriptionFooter } from '../components/subscription-footer';

import { trackPurchase } from '../shared'

import './style.scss';

export const SubscriptionScreen = React.memo(() => {

  const dispatch = useDispatch();

  const paymentModalRef = useRef(null);
  const containerRef = useRef(null);

  const isLoaded = useSelector(state => !state.billing.loading);
  const products = useSelector(state => selectProducts(state));

  const [selectedProduct, setSelectedProduct] = useState(products.find(p => p.default) || products[0]);

  useEffect(() => {
    setSelectedProduct(products.find(p => p.default) || products[0]);
  }, [isLoaded, products]);

  const handleButtonClick = useCallback(() => {
    paymentModalRef.current?.showPaymentPopup();
  }, []);

  const handleProductClick = useCallback((product) => {
    setSelectedProduct(product);
    paymentModalRef.current?.showPaymentPopup();
  }, []);

  const handleSecondaryButtonClick = useCallback(() => {
    containerRef.current?.scrollTo({top: 9999, behavior: 'smooth'});
    paymentModalRef.current?.showPaymentPopup();
  }, []);

  const handlePurchaseSuccess = useCallback((data) => {
    trackPurchase(data)
    dispatch(setPurchasedStatus(true));
    dispatch(openAppLink());
  }, []);

  const handlePurchaseError = useCallback((error) => {
    // alert(error.data.message);
  }, []);

  return (
    <div className={'subscription-screen'} ref={containerRef}>
      {selectedProduct ? (
        <PaymentProcessor
          activeProduct={selectedProduct}
          ref={paymentModalRef}
          onSuccess={handlePurchaseSuccess}
          onError={handlePurchaseError}/>
      ) : null}
      <AppInfo/>
      <QuizScreen onButtonClick={handleSecondaryButtonClick} gradientTop={false}/>
      <StoreReviews/>
      <SubscriptionForm beforeFooter>
        {isLoaded && products.length > 0 && selectedProduct && <Plans
          activeProduct={selectedProduct}
          products={products}
          showTerms
          showButton
          onProductClick={handleProductClick}
          onButtonClick={handleButtonClick}
        />}
      </SubscriptionForm>
      <SubscriptionFooter/>
    </div>
  );
});
