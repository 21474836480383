import React, {ChangeEvent, useState} from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface Props {
    isChecked: boolean;
    onChange: () => void;
    classNameCheckbox?: string;
}

const Checkbox: React.FC<Props> = ({isChecked, onChange, classNameCheckbox}) => {
    const [checked, setChecked] = useState(isChecked);
    const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
        setChecked(!checked);
        onChange?.call(e);
    };
    return (
        <div className={classes.checkbox}>
            <input type="checkbox" id="checkbox" defaultChecked={isChecked} onChange={handleChecked}/>
            <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={classNames(classes.checkbox__mark, classNameCheckbox)}>
                <circle cx="7.90601" cy="7.53418" r="6.375" stroke="#E6E4EB" strokeWidth="2.25"/>
                <circle cx="7.90601" cy="7.53418" r="3.75" fill={checked ? "#FFCC19" : "none"}/>
            </svg>
        </div>
    );
}

export default Checkbox;
