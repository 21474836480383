import { Stars } from '../stars';

import './style.scss';

export const ReviewRating = ({imageClassName, storeSrc, storeSrcSet, rating = '3,401'}) => (
  <div className='subscription-store-reviews-stats-store'>
    <img className={`store-icon ${imageClassName}`}
         src={storeSrc}
         srcSet={storeSrcSet}/>
    <div className='rating'>
      <Stars className='rating-image' rating={5}/>
      <div className='rating-text'>
        <strong>{rating}</strong> Reviews
      </div>
    </div>
  </div>
);
