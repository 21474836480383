import {
  INITIAL_CONFIG as CORE_INITIAL_CONFIG,
  type RemoteConfig as CoreRemoteConfig,
} from '@web-solutions/core/constants/remote-config';

export enum SummarySection {
  MONEY_BACK = 'money_back',
  FEEDBACK_LIST = 'feedback_list',
  DOWNLOADED = 'downloaded',
};

export interface RemoteConfig extends CoreRemoteConfig {
  landingStack: Array<SummarySection | 'plans' | 'reserved_price'>,
  landingTopButtonAfterBlockType: SummarySection[],

  headerTitle: string,
  headerSubTitle: string,

  flow: Array<string | string[]>,

  magicDurationLoad: number,
  magicTitle: string,
  magicStages: string[],

  emailPageTitle: string,
  emailPageSections: SummarySection[],
  emailPageBlurred: boolean,

  quizWelcomePage: {
    title: string,
    subtitle: string,
    button: string,
  },

  feedbacks: { title: string, text: string, imgKey?: string }[],

  downloadedSection: {
    title: string,
    appstoreTitle: string,
    appstoreRating: string,
    playstoreTitle: string,
    playstoreRating: string,
  },
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...CORE_INITIAL_CONFIG,

  landingStack: [],
  landingTopButtonAfterBlockType: [],

  headerTitle: '',
  headerSubTitle: '',

  flow: [],

  magicDurationLoad: 8000,
  magicTitle: '',
  magicStages: [],

  emailPageTitle: '',
  emailPageSections: [],
  emailPageBlurred: false,

  quizWelcomePage: {
    title: '',
    subtitle: '',
    button: '',
  },

  feedbacks: [],

  downloadedSection: {
    title: '',
    appstoreTitle: '',
    appstoreRating: '',
    playstoreTitle: '',
    playstoreRating: '',
  },
};
