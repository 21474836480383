import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import "./style.css";

import Analytics from "@web-solutions/module-analytics";
import { completeStepAndNavigate } from "@web-solutions/giantcrush/src/store/progress/actions";
import { QuizTutorial } from "src/components/connected/quiz-tutorial";
import { Crosshair } from "src/components/connected/quiz-gameplay-preview/crosshair";

const CROSSHAIR_CFG = {
  LEFT_LEG: {
    position: {
      x: 110,
      y: 120,
    },
    collider: {
      width: 60,
      height: 120,
    }
  },
  BODY: {
    position: {
      x: 0,
      y: -150,
    },
    collider: {
      width: 100,
      height: 100,
    }
  },
  HEAD: {
    position: {
      x: 0,
      y: -360,
    },
    collider: {
      width: 70,
      height: 70,
    }
  }
}

const VIRTUAL_VIDEOS_CFG = [
  {
    start: 0,
    duration: 2.983 - 0.064,
    skipIfInteracted: true,
    loopUntilInteracted: true,
    crosshair: CROSSHAIR_CFG.LEFT_LEG
  },
  {
    start: 2.983 + 0.032,
    duration: 3.983 - 0.064,
    autoContinue: true,
    crosshair: CROSSHAIR_CFG.LEFT_LEG
  },
  {
    start: 6.966 + 0.032,
    duration: 2.633 - 0.128,
    skipIfInteracted: true,
    loopUntilInteracted: true,
    crosshair: CROSSHAIR_CFG.LEFT_LEG
  },
  {
    start: 9.599,
    duration: 3 - 0.064,
    autoContinue: true,
    crosshair: CROSSHAIR_CFG.BODY
  },
  {
    start: 12.599 + 0.032,
    duration: 2.983 - 0.064,
    skipIfInteracted: true,
    loopUntilInteracted: true,
    crosshair: CROSSHAIR_CFG.BODY
  },
  {
    start: 15.582 + 0.032,
    duration: 4.017 - 0.064,
    autoContinue: true,
    crosshair: CROSSHAIR_CFG.HEAD
  },
  {
    start: 19.599 + 0.032,
    duration: 2.533 - 0.064,
    skipIfInteracted: true,
    loopUntilInteracted: true,
    crosshair: CROSSHAIR_CFG.HEAD
  },
  {
    start: 22.133 + 0.032,
    duration: 4 - 0.064,
    crosshair: CROSSHAIR_CFG.BODY
  }
];

export const QuizGameplayPreview = React.memo(({
                                                 src = "",
                                                 stepKey = '',
                                                 isPreload = false,
                                                 onClick,
                                               }) => {
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const [interacted, setInteracted] = useState(false);
  const [interacting, setInteracting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [virtualVideoIndex, setVirtualVideoIndex] = useState(-1);
  
  useEffect(() => {
    if (!isPreload) {
      // console.log('reset time');
      videoRef.current.currentTime = 0;
    }
  }, [isPreload])

  useEffect(() => {
    if (virtualVideoIndex < 0) return;
    if (virtualVideoIndex > VIRTUAL_VIDEOS_CFG.length - 1) {
      // console.error('index OOB');
      return;
    }

    const videoCfg = VIRTUAL_VIDEOS_CFG[virtualVideoIndex];
    // console.error('new index', virtualVideoIndex, videoCfg, interacting);

    if (interacting) {
      const prevVideoCfg = VIRTUAL_VIDEOS_CFG[virtualVideoIndex - 1];
      
      if (prevVideoCfg?.skipIfInteracted) {
        videoRef.current.currentTime = videoCfg.start - 0.1;
        // console.error('skipped time', videoRef.current.currentTime);
      }
    }

    const interval = setInterval(() => {
      if (!videoRef?.current) return;
      
      // console.log(videoRef.current.currentTime);
      if (videoRef.current.currentTime >= videoCfg.start + videoCfg.duration
        || videoRef.current.currentTime === videoRef.current.duration) {
        if (videoCfg.loopUntilInteracted) {
          videoRef.current.currentTime = videoCfg.start;
          if (videoRef.current.paused) {
            videoRef.current.play();
          }
        } else if (videoCfg.autoContinue) {
          setVirtualVideoIndex(virtualVideoIndex + 1);
        }
      }
    }, 16)
    
    return () => clearInterval(interval);
  }, [virtualVideoIndex]);
  
  const navigate = () => {
    Analytics.trackEvent('step', 'completed', {
      id: stepKey
    });

    if (typeof onClick === 'function') {
      onClick();
      return;
    }

    dispatch(completeStepAndNavigate(stepKey, 1));
  };


  const handleLoad = () => {
    if (!loaded) {
      setVirtualVideoIndex(0);
    }
    setLoaded(true);
  };

  const handleTouchStart = () => {
    if (isPreload) {
      return;
    }
    setInteracting(true);
    setInteracted(true);

    if (videoRef.current.paused) {
      videoRef.current.play();
    }
    tryNextIndex();
  }
  const handleTouchMove = () => {
    if (isPreload) {
      return;
    }
    if (interacting) {
      tryNextIndex();
    }
  }
  const handleTouchEnd = () => {
    if (isPreload) {
      return;
    }
    setInteracting(false);
  }
  
  const tryNextIndex = () => {
    const videoCfg = VIRTUAL_VIDEOS_CFG[virtualVideoIndex];
    if (!videoCfg || !videoRef.current) return;
    if (videoRef.current.currentTime >= videoCfg.start &&
      videoRef.current.currentTime < videoCfg.start + videoCfg.duration) {
      setVirtualVideoIndex(virtualVideoIndex + 1);
    }
  }
  
  const onEnded = () => {
    navigate();

    setVirtualVideoIndex(0);
  }

  const videoCfg = VIRTUAL_VIDEOS_CFG[Math.min(virtualVideoIndex, VIRTUAL_VIDEOS_CFG.length - 1)];
  
  return (
    <div className={`quiz-gameplay-preview ${isPreload ? 'hidden' : ''}`}>
      <video
        ref={videoRef}
        src={process.env.REACT_APP_PUBLIC_URL + src}
        autoPlay
        playsInline={true}
        className='quiz-gameplay-preview-video'
        onCanPlayThrough={handleLoad}
        onEnded={onEnded}/>
      {loaded && !interacted ? <QuizTutorial/> : undefined}
      {!loaded ? (
        <>
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/QuizContent/Videos/preview.jpg`}
            className='quiz-gameplay-preview-loading-img'/>
          <span className='quiz-gameplay-preview-loading-text'>...</span>
        </>
      ) : (
        <div
          className='quiz-gameplay-preview-interaction-area'
          onMouseDown={handleTouchStart}
          onMouseMove={handleTouchMove}
          onMouseUp={handleTouchEnd}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}>
          {videoCfg && 'crosshair' in videoCfg ?
            <Crosshair
              position={videoCfg.crosshair.position}
              collider={videoCfg.crosshair.collider} /> : undefined}
        </div>
      )}
    </div>
  );
});
