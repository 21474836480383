import _map from 'lodash/map';
import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface SmallBadgeProps {
  texts?: string[],
  disabled?: boolean,
}

export const SmallBadge: React.FC<SmallBadgeProps> = ({
  texts,
  disabled,
}) => {
  return (
    <span className={classNames(classes.root, { [classes.disabled]: disabled })}>
      {_map(texts, text =>
        <span key={text} className={classes.text}>{text}</span>
      )}
    </span>
  );
};
