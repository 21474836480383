import './style.scss';

const MAX_RATING = 5;

export const Stars = ({className, rating = MAX_RATING}) => (
  <div className={`stars-container ${className}`}>
    <div className='mask' style={{
      width: `${100 * rating / MAX_RATING}%`,
    }}>
      <img className='active'
           src={`${process.env.REACT_APP_PUBLIC_URL}/assets/UI/Stars.svg`}
           alt={`${rating} star rating`}/>
    </div>
    <img className='inactive'
         src={`${process.env.REACT_APP_PUBLIC_URL}/assets/UI/StarsGray.svg`}/>
  </div>
);
