import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';

import classes from './style.module.scss';

const MoneyBackDescription = () => {
  const { policyLinks } = useSelector(remoteConfigSelector);

  return (
    <>
      <div className={classNames(classes.title)}>
        Money-back guarantee
      </div>
      <p className={classNames(classes.root, classes.short, 'description_light')}>
        In case you don’t get visible results, you can get a full refund within 30 days after purchase.
        <a href={policyLinks.moneyBack} target='_blank'>
          Learn more about money-back
        </a>
      </p>
    </>
  );
};

MoneyBackDescription.propTypes = {
  className: PropTypes.any,
};

export default MoneyBackDescription;
