import './style.css';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Analytics from '@web-solutions/module-analytics';

import { ProgressBar } from 'src/components/common/progress-bar';

import { QuizScreen } from 'src/screens/quiz/quiz-screen';

import DATA from '../data/data.json';
import { preloadAssetsAtIndex } from 'src/store/progress/actions';
import { QuizGameplayPreview } from 'src/components/connected/quiz-gameplay-preview';
import { SubscriptionScreen } from 'src/screens/subscription';
import { trackContact, trackLead } from 'src/fbAnalytics';
import { QuizBackgroundImage } from 'src/components/common/quiz-background-image';

export const RootView = React.memo(() => {
  const appLoaded = useSelector(state => state.app.loaded);
  const currentStepIndex = useSelector(state => state.progress.currentStepIndex);
  const completed = useSelector(state => state.progress.completed);
  const quizScreensOrder = useSelector(state => state.remoteConfig.quizScreensOrder);

  const [quizScreenId, setQuizScreenId] = useState(null);
  const [quizScreenModel, setQuizScreenModel] = useState(null);
  const [quizImageSrc, setQuizImageSrc] = useState(null);
  const [quizGameplayPreviewId, setQuizGameplayPreviewId] = useState(null);
  const [quizGameplayPreviewModel, setQuizGameplayPreviewModel] = useState(null);
  const [visibleStepsCount, setVisibleStepsCount] = useState(1);

  useEffect(() => {
    if (!quizScreensOrder) return;
    let count = 0;
    for (let i = 0; i < quizScreensOrder.length; i++) {
      const quizScreenId = quizScreensOrder[i];
      const quizScreenModel = DATA.quizScreens[quizScreenId];

      if (!quizScreenModel.hidden) {
        count += 1;
      }
    }
    
    setVisibleStepsCount(count);
  }, [quizScreensOrder]);
  
  useEffect(() => {
    if (quizScreenId) {
      Analytics.trackEvent('step', 'started', {
        id: quizScreenId
      });
    }
  }, [quizScreenId]);

  useEffect(() => {
    if (!quizScreensOrder) return;
    
    const quizScreenId = quizScreensOrder[currentStepIndex];

    if (quizScreenId === 'YOUR_EMAIL') {
      trackLead();
    }

    preloadAssetsAtIndex(currentStepIndex + 1, quizScreensOrder);
    preloadAssetsAtIndex(currentStepIndex - 1, quizScreensOrder);

    if (currentStepIndex === 1) {
      trackContact();
    }
  }, [currentStepIndex]);

  
  useEffect(() => {
    if (!quizScreensOrder) return;
    
    const id = quizScreensOrder[currentStepIndex];
    const model = DATA.quizScreens[id];
    
    setQuizScreenId(id);
    setQuizScreenModel(model);
    setQuizImageSrc(model?.content?.find(entry => 'QuizImage' in entry)?.QuizImage);
    
  }, [quizScreensOrder, currentStepIndex]);
  
  useEffect(() => {
    for (let screenKey in DATA.quizScreens) {
      const model = DATA.quizScreens[screenKey].content.find(entry => 'QuizGameplayPreview' in entry);
      if (model) {
        setQuizGameplayPreviewModel(model);
        setQuizGameplayPreviewId(screenKey);
        break;
      }
    }
  });

  return (
    <>
      {completed && appLoaded && (
        <SubscriptionScreen/>
      )}
      {!completed && appLoaded && (
        <>
          <QuizBackgroundImage src={quizImageSrc}/>
          <div className='quiz-wrapper'>
            <div className='quiz-container'>
              <div className='quiz-frame'>
                {quizScreenModel ? (
                  <ProgressBar current={currentStepIndex + 1} max={visibleStepsCount} hidden={quizScreenModel.hidden}/>
                ) : null}
                {quizGameplayPreviewModel ? (
                  <QuizGameplayPreview stepKey={quizGameplayPreviewId}
                                       src={quizGameplayPreviewModel.QuizGameplayPreview.src}
                                       isPreload={quizScreenId !== quizGameplayPreviewId}/>
                ) : undefined}
                {quizScreenModel ? (
                  <QuizScreen key={currentStepIndex}
                              screenModelId={quizScreenId}
                              screenModel={quizScreenModel}/>
                ) : null}
              </div>
            </div>
          </div>
        </>

      )}
      {!appLoaded &&
      <div className={`blur-overlay`}>
        <div className='loader'>
          <div>{'WAIT...'}</div>
        </div>
      </div>
      }
    </>
  );
});