export const trackContact = () => {
  window.fbq('track', 'Contact');
};

export const trackLead = () => {
  window.fbq('track', 'Lead');
};

export const trackCompleteRegistration = () => {
  window.fbq('track', 'CompleteRegistration');
};

export const trackSubscribe = (value = 0, currency = 'USD', predictedLtv = 0) => {
  window.fbq('track', 'Subscribe', {value: value.toString(), currency, predicted_ltv: predictedLtv.toString()});
};

export const trackCustom = (name = '', params) => {
  window.fbq('trackCustom', name, params);
};
