import React from 'react';

import './style.scss';

export const BlurContainer = (
  {
    gradientTop = true,
    gradientBottom = true,
    beforeFooter = false,
  }) => (
  <div className={`subscription-image-blur-container ${beforeFooter ? 'before-footer' : ''}`}>
    {gradientTop ? <div className={'gradient-top'}/> : null}
    <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/QuizContent/Images/sub.jpg`}/>
    {gradientBottom ? <div className={'gradient-bottom'}/> : null}
  </div>
);
