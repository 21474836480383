// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x2LL0VPYQXcweyHllNI2{height:50px}", "",{"version":3,"sources":["webpack://./../../packages/core/payment/components/apple-pay/style.module.scss"],"names":[],"mappings":"AAGA,sBACE,WAAA","sourcesContent":["$colorTheme: artpix;\n@import \"src/styles\";\n\n.btn {\n  height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "x2LL0VPYQXcweyHllNI2"
};
export default ___CSS_LOADER_EXPORT___;
