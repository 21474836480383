// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rogp14axv3dmlhJxKEWf{padding:16px 0;display:flex;flex-direction:column;align-items:center}.rogp14axv3dmlhJxKEWf .GyrpzOxEtufoMWPWbdwW{position:absolute;top:22px;right:10px;padding:0 10px}.rogp14axv3dmlhJxKEWf .yVf9lELzWC7VYDg1csfw{padding:0 16px}.rogp14axv3dmlhJxKEWf .N0ebc9k4dEkGMG8A7kSg{padding:0 32px}.rogp14axv3dmlhJxKEWf .N0ebc9k4dEkGMG8A7kSg button{left:2px}.rogp14axv3dmlhJxKEWf .xZ8n6GJMrpJ5FgVS1lTg{margin-top:8px;margin-bottom:8px}", "",{"version":3,"sources":["webpack://./../../packages/manage-subscription/src/manage/components/plans/style.module.scss"],"names":[],"mappings":"AAGA,sBACE,cAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CAEA,4CACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,cAAA,CAGF,4CACE,cAAA,CAGF,4CACE,cAAA,CACA,mDACE,QAAA,CAIJ,4CACE,cAAA,CACA,iBAAA","sourcesContent":["$colorTheme: artpix;\n@import \"src/styles\";\r\n\r\n.wrap {\r\n  padding: 16px 0;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n\r\n  .close {\r\n    position: absolute;\r\n    top: 22px;\r\n    right: 10px;\r\n    padding: 0 10px;\r\n  }\r\n\r\n  .description {\r\n    padding: 0 16px;\r\n  }\r\n\r\n  .header {\r\n    padding: 0 32px;\r\n    button {\r\n      left: 2px;\r\n    }\r\n  }\r\n\r\n  .btn {\r\n    margin-top: 8px;\r\n    margin-bottom: 8px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "rogp14axv3dmlhJxKEWf",
	"close": "GyrpzOxEtufoMWPWbdwW",
	"description": "yVf9lELzWC7VYDg1csfw",
	"header": "N0ebc9k4dEkGMG8A7kSg",
	"btn": "xZ8n6GJMrpJ5FgVS1lTg"
};
export default ___CSS_LOADER_EXPORT___;
