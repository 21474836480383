import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import "./style.css";

import Analytics from "@web-solutions/module-analytics";
import {
  completeStepAndNavigate,
} from "@web-solutions/giantcrush/src/store/progress/actions";
import { t } from '@web-solutions/module-localization';
import { QuizButton } from 'src/components/connected/quiz-button';
import { processEmail } from 'src/store/profile/actions';
import { trackCompleteRegistration } from 'src/fbAnalytics';

const EMAIL_REGEX = /^[a-zA-Z0-9\+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g;
const VALIDATION = {
  INVALID: -1,
  NOT_DETERMINED: 0,
  VALID: 1,
}

const validateEmail = (email) => {
  return email.match(EMAIL_REGEX)?.length > 0 ? VALIDATION.VALID : VALIDATION.INVALID;
}

export const QuizEmailForm = React.memo(({
                                           stepKey = "",
                                           text = "",
                                           placeholder = "",
                                           details = "",
                                           validationFormat = "",
                                           quizButton = "",
                                           animStartIndex = 0,
                                           animClassOpacity = '',
                                           animClassSlide = '',
                                           onContinue,
                                         }) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [valid, setValid] = useState(VALIDATION.NOT_DETERMINED);


  const handleChange = (e) => {
    setValid(validateEmail(e.target.value));
  };

  let validationClassName = '';
  switch (valid) {
    case VALIDATION.INVALID:
      validationClassName = 'invalid';
      break;
    case VALIDATION.NOT_DETERMINED:
      validationClassName = '';
      break;
    case VALIDATION.VALID:
      validationClassName = 'valid';
      break;
  }

  const handleSubmit = () => {
    if (valid !== VALIDATION.VALID) {
      inputRef.current.focus();
      return;
    }
    
    onContinue().then(() => {
      Analytics.trackEvent('step', 'completed', {
        id: stepKey
      });
      dispatch(completeStepAndNavigate(stepKey, 1));
      dispatch(processEmail(inputRef.current.value));
      trackCompleteRegistration();
    });
  }

  return (
    <div className={`quiz-email-form`}>
      <div className={`quiz-email-form-text ${animClassOpacity} animation-delay-${animStartIndex}`}>
        {text}
      </div>
      <input ref={inputRef}
             className={`quiz-email-form-input ${validationClassName} ${animClassOpacity} animation-delay-${animStartIndex}`}
             placeholder={placeholder}
             onChange={handleChange} />
      <div className={'quiz-email-form-validation-text'}>
        {validationFormat}
      </div>
      <div className={`quiz-email-form-details ${animClassOpacity} animation-delay-${animStartIndex}`}>
        {details}
      </div>
      <QuizButton stepKey={stepKey}
                  title={quizButton}
                  className={`${animClassSlide} animation-delay-${animStartIndex + 1}`}
                  onClick={handleSubmit} />
    </div>
  );
});
