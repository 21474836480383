// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LCgqfLAqbe5CCVmW7uhW{width:100%;height:100%;border-radius:30px;overflow:hidden;position:absolute;top:0}.yYXxxCxIRZa1UlzBgR6X{width:100%;margin:0;opacity:0}.HMEynRm6TWH0IVnixYJ0{width:100%;height:55px}", "",{"version":3,"sources":["webpack://./../../packages/core/summary/injected-payment-method/components/button/style.module.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CACA,WAAA,CACA,kBAAA,CACA,eAAA,CACA,iBAAA,CACA,KAAA,CAGF,sBACE,UAAA,CACA,QAAA,CACA,SAAA,CAGF,sBACE,UAAA,CACA,WAAA","sourcesContent":["$colorTheme: artpix;\n@import 'src/styles';\n\n.button {\n  width: 100%;\n  height: 100%;\n  border-radius: 30px;\n  overflow: hidden;\n  position: absolute;\n  top: 0;\n}\n\n.paypal {\n  width: 100%;\n  margin: 0;\n  opacity: 0;\n}\n\n.default {\n  width: 100%;\n  height: 55px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "LCgqfLAqbe5CCVmW7uhW",
	"paypal": "yYXxxCxIRZa1UlzBgR6X",
	"default": "HMEynRm6TWH0IVnixYJ0"
};
export default ___CSS_LOADER_EXPORT___;
