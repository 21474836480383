import React from 'react';
import Modal from 'react-modal';

import { ROUTES } from 'core/constants/routes';

import classes from './style.module.scss';

interface Props {
  verifyUrl: string;
  onSuccess: () => void;
  onFail: () => void;
}

export const Verify3dsModal: React.FC<Props> = ({ verifyUrl, onSuccess, onFail }) => {
  const handleOnLoadIframe = (e: React.ChangeEvent<HTMLIFrameElement>) => {
    try {
      const pathName = e.target?.contentWindow?.location?.pathname;

      if (pathName === ROUTES.VERIFY_3DS_SUCCESS) {
        onSuccess();
      } else if (pathName === ROUTES.VERIFY_3DS_FAIL) {
        onFail();
      }
    } catch (error) { }
  }

  return (
    <Modal
      isOpen={!!verifyUrl}
      style={{
        overlay: {
          zIndex: 100,
        }
      }}
      className={classes.iframe_modal}
    >
      <iframe
        onLoad={handleOnLoadIframe}
        title="verifyIframe"
        id="verifyIframe"
        className={classes.iframe}
        src={verifyUrl}
      />
    </Modal>
  )
};
