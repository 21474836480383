// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hq4q4iHFWibxEq_M4xco{width:100%;font-size:0.75rem;font-weight:500}", "",{"version":3,"sources":["webpack://./../../packages/core/payment/components/trial-reminder/style.module.scss","webpack://./../../packages/core/styles/_variables.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CACA,iBCQW,CDPX,eC+BK","sourcesContent":["$colorTheme: artpix;\n@import \"src/styles\";\n\n.reminder {\n  width: 100%;\n  font-size: $fontSize12;\n  font-weight: $fwsb;\n}","$maxButtonWidth: 315px;\r\n$maxWidthDefault: 375px;\r\n$maxWidthFull: 450px;\r\n$minHeightDefault: 668px;\r\n\r\n$pageTransitionDelay: 200ms;\r\n$pageTransitionDuration: 400ms;\r\n\r\n// font-size\r\n$fontSizeHtml: 100%; // 62.5% of 16px = 10px\r\n$fontSize9: rem(9); // 9px\r\n$fontSize10: rem(10); // 10px\r\n$fontSize11: rem(11); // 11px\r\n$fontSize12: rem(12); // 12px\r\n$fontSize13: rem(13); // 13px\r\n$fontSize14: rem(14); // 14px\r\n$fontSize15: rem(15); // 15px\r\n$fontSize16: rem(16); // 16px\r\n$fontSize17: rem(17); // 17px\r\n$fontSize18: rem(18); // 18px\r\n$fontSize20: rem(20); // 20px\r\n$fontSize22: rem(22); // 22px\r\n$fontSize23: rem(23); // 23px\r\n$fontSize24: rem(24); // 24px\r\n$fontSize26: rem(26); // 26px\r\n$fontSize28: rem(28); // 28px\r\n$fontSize30: rem(30); // 30px\r\n$fontSize32: rem(32); // 32px\r\n$fontSize34: rem(34); // 34px\r\n$fontSize38: rem(38); // 38px\r\n$fontSize40: rem(40); // 40px\r\n$fontSize44: rem(44); // 44px\r\n$fontSize48: rem(48); // 48px\r\n$fontSize50: rem(50); // 50px\r\n$fontSize55: rem(55); // 55px\r\n\r\n$fwn: 400; // font weight normal\r\n$fwsb: 500; // font semi bold\r\n$fwb: 600; // font weight bold\r\n$fwstb: 700; // font weight bold\r\n$fwexb: 800; // font weight extra bold\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reminder": "hq4q4iHFWibxEq_M4xco"
};
export default ___CSS_LOADER_EXPORT___;
