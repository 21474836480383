import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { InjectedPaymentMethod } from 'core/constants/remote-config';
import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { setInjectedPaymentMethod } from 'core/store/billing/actions';
import { useShowPayPal, useShowApplePay } from 'core/hooks/use-show-payment-method';
import { ModeGooglePay } from 'core/constants/billing';

import { ReactComponent as Check } from './icons/check.svg';

import classes from './style.module.scss';

interface Props {
  isBaseSwitch?: boolean;
}

const InjectedPaymentMethodSwitch: React.FC<Props> = ({ isBaseSwitch = false }) => {
  const { defaultInjectedPaymentMethod, modeGooglePay, injectedPaymentMethods } = useSelector(remoteConfigSelector);
  const injectedPaymentMethod = useSelector((state) => state.billing.injectedPaymentMethod);
  const dispatch = useDispatch();
  const showPayPal = useShowPayPal();
  const showApplePay = useShowApplePay();
  const showGooglePay = modeGooglePay !== ModeGooglePay.DISABLED;

  const methods = {
    [InjectedPaymentMethod.APPLEPAY] : { show: showApplePay },
    [InjectedPaymentMethod.PAYPAL]: { show: showPayPal },
    [InjectedPaymentMethod.CARD]: { show: true },
    [InjectedPaymentMethod.GOOGLEPAY]: { show: showGooglePay },
    [InjectedPaymentMethod.GOOGLEPAY_PAYPAL]: { show: showGooglePay || showPayPal },
    [InjectedPaymentMethod.APPLEPAY_PAYPAL]: { show: showApplePay || showPayPal },
  };

  useEffect(() => {
    if (!injectedPaymentMethod) {
      dispatch(setInjectedPaymentMethod(defaultInjectedPaymentMethod));
    }
  }, []);

  const handleButtonClick = (id: string) => {
    dispatch(setInjectedPaymentMethod(id));
  }

  const getButton = (id) => {
    switch(id) {
    case InjectedPaymentMethod.GOOGLEPAY_PAYPAL:
      return (
        <div className={classes.imgContainer}>
          <div className={classes.container}>
            <div className={classes[`${InjectedPaymentMethod.GOOGLEPAY}-base`]} />
            <div className={classNames(classes[`${InjectedPaymentMethod.PAYPAL}-base`], classes.bottom)} />
          </div>
        </div>
      );
    case InjectedPaymentMethod.APPLEPAY_PAYPAL:
      return (
        <div className={classes.imgContainer}>
          <div className={classes.container}>
            <div className={classes[`${InjectedPaymentMethod.APPLEPAY}-base`]} />
            <div className={classNames(classes[`${InjectedPaymentMethod.PAYPAL}-base`], classes.bottom)} />
          </div>
        </div>
      );
    default:
      return (
        <div className={classes.imgContainer}>
          <div className={classes.imgContainer}>
            {!isBaseSwitch && <div className={classes[`${id}-dark`]} />}
            <div className={isBaseSwitch ? classes[`${id}-base`] : classes[id]} />
          </div>
        </div>
      );
    }
  }

  return (
    <div className={classNames(classes.wrapper, isBaseSwitch && classes.base)}>
      {
        injectedPaymentMethods.map((id) => {
          // @ts-ignore
          const { show } = methods[id];

          return show && (
            <div
              key={id}
              className={classNames(classes.method, {[classes.active]: injectedPaymentMethod === id})}
              onClick={() => handleButtonClick(id)}
            >
              <div className={classes.check}>
                <Check className={classes.icon}/>
              </div>
              {getButton(id)}
            </div>
          )
        })
      }
    </div>
  );
}

export default InjectedPaymentMethodSwitch;
