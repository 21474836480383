import React from 'react';

import { BlurContainer } from '../blur-container';

import './style.scss';

export const SubscriptionForm = ({gradientTop = true, beforeFooter = false, children}) => (
  <div className={'subscription-form'}>
    <BlurContainer gradientTop={gradientTop} beforeFooter={beforeFooter}/>
    <div className={'title'}>GET A SWEAT REWARD</div>
    <div className={'content'}>
      {children}
    </div>
  </div>
);
