import Attribution from '@web-solutions/module-attribution';

import { init as initRemoteConfig } from '@web-solutions/core/store/remote-config/actions';
import { setLoaded, initAppLink } from '@web-solutions/base-app/store/app/actions';

import { auth } from '@web-solutions/base-app/store/profile/actions';
import { init } from 'core/store/billing/actions';

import { INITIAL_CONFIG } from 'src/constants/remote-config';

import { getMagnusToken } from './utils';
import { initLocalization } from 'src/localization';

export const load = () => async (dispatch, getState) => {

  try {
    const {appLinks, appName} = await dispatch(initRemoteConfig(getMagnusToken(), INITIAL_CONFIG));
    Attribution.init(appLinks);

    initLocalization(appName);
    try {
      await dispatch(auth());
    } catch (e) {
      // debugger
    }
    try {

      await dispatch(initAppLink());
    } catch (e) {
      // debugger
    }
    await dispatch(init());
  } catch (error) {
    console.warn('[ERROR INIT APP]', error);
  } finally {
    const state = getState();
    if (state.progress.purchased) {
      dispatch(openAppLink());
      return;
    }
    dispatch(setLoaded(true));
  }
};

export const openAppLink = () => async () => {
  window.location.href = 'https://joyhub.app/giantcrush/thank-you.html';
  // window.location.href = await Attribution.getLinkToApp();
};
