import React from 'react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { useRemoteConfig } from 'core/hooks/use-remote-config';

import { useTimer } from '../../../payment/hooks/use-timer';

import classes from './style.module.scss';

const tKey = 'core.payment_popup';

interface DiscountWithTimerProps {
  discount?: string,
  badgeTitle: string,
  centered?: boolean,
  disabled?: boolean,
}

export const DiscountWithTimer: React.FC<DiscountWithTimerProps> = ({
  discount,
  badgeTitle,
  centered,
  disabled,
}) => {
  const { showTimerInPlanBadge } = useRemoteConfig();
  const timer = useTimer();
  const isShownTimer = timer?.shown && showTimerInPlanBadge;
  const discountTitle = badgeTitle || t(`${tKey}.${discount && isShownTimer ? 'discount' : 'discount_save'}`, { discount });
  const isShownTimerBlock = !!discount && isShownTimer;

  if (discount || badgeTitle) {
    return (
      <div className={classNames(classes.root, { [classes.centered]: centered, [classes.disabled]: disabled })}>
        <div className={classNames(classes.container)}>
          <div className={classes.timerContainer}>
            <span className={classes.text}>{discountTitle}</span>
            {isShownTimerBlock && (
              <span className={classes.timerText}>{timer?.text}</span>
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
};
