import "./style.css";

import React from "react";
import { Button } from "..";

export const MajorButton = React.memo(({
  text,
  visible = true,
  className = "",
  ...props
}) => {
  return (
    <Button className={`major-btn ${className} ${visible ? "" : "hidden"}`} {...props}>
      {text}
    </Button>
  )
});