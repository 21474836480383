import _intersection from 'lodash/intersection';
import { useResolvedPath, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import { PaymentSystem } from '@web-solutions/react-billing';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { selectOneTimePurchases } from '@web-solutions/core/store/billing/selectors';

import { selectPaymentSystem, selectProduct } from '../slice';

import { REASONS_IDS } from '../constants/general';

import { ROUTES } from '../constants/routes';

import { useManageContext } from './use-manage-context';

const defaultStart = [
  ROUTES.MAIN,
  ROUTES.BENEFITS,
  ROUTES.REASON,
]

const defaultEnd = [
  ROUTES.TERMINATE_SUBSCRIPTION,
  ROUTES.CANCEL_SUCCESS
]

const variant_1 = [
  ROUTES.OFFER_FREE_PERIOD,
  ROUTES.FAREWELL_OFFER,
]

const variant_2 = [
  ROUTES.FAREWELL_OFFER,
  ROUTES.OFFER_CHEAP_PRODUCT,
  ROUTES.OFFER_FREE_PERIOD,
]

const variant_3 = [
  ROUTES.OFFER_CHEAP_PRODUCT,
  ROUTES.OFFER_FREE_PERIOD,
  ROUTES.FAREWELL_OFFER,
]

const variant_4 = [
  ROUTES.PROGRESS,
  ROUTES.FAREWELL_OFFER,
]

const variant_5 = [
  ROUTES.PLANS,
  ROUTES.OFFER_CHEAP_PRODUCT,
  ROUTES.FAREWELL_OFFER,
]

const ROUTE_VARIANTS = {
  [REASONS_IDS.CHANGE_MIND]: variant_3,
  [REASONS_IDS.DONT_LIKE]: variant_1,
  [REASONS_IDS.DONT_USE]: variant_4,
  [REASONS_IDS.LONGER_PLAN]: variant_5,
  [REASONS_IDS.NO_TIME]: variant_1,
  [REASONS_IDS.OTHER]: variant_3,
  [REASONS_IDS.PRICE]: variant_3,
  [REASONS_IDS.PROBLEMS]: variant_1,
  [REASONS_IDS.REPORT]: variant_2,
  [REASONS_IDS.TRIAL]: variant_1,
}

const getActualVariant = () => {
  const params = queryString.parse(window.location.search);
  const reason = params.reason as unknown as string;
  const routes = reason ? ROUTE_VARIANTS[reason] : ROUTE_VARIANTS[REASONS_IDS.TRIAL];
  return [...defaultStart, ...routes, ...defaultEnd];
}

const getNextPage = (location: string) => {
  const routes = getActualVariant();
  const nextPageIndex = routes.findIndex((route) => route === location) + 1;
  return routes[nextPageIndex]
}

const getPrevPage = (location: string) => {
  const routes = getActualVariant();
  const prevPageIndex = routes.findIndex((route) => route === location) - 1;
  return routes[prevPageIndex]
}

export const useNavigateManage = () => {
  const { mngProgressPage, mngSubBenefitsPage, mngSubReasonPage, mngSubFarewellOfferPage, mngSubOfferFreePeriodPage, mngSubOfferCheapProductPage } = useRemoteConfig();
  const oneTimePurchases = useSelector(selectOneTimePurchases);
  const paymentSystem = useSelector(selectPaymentSystem);
  const currentProduct = useSelector(selectProduct);
  const purchasesCodes = oneTimePurchases.map((purchase) => purchase.product_code)
  const { productCodes = [] } = useManageContext();
  const navigate = useNavigate();
  const match = useResolvedPath("");

  const isPageAvailable = (page: string) => {
    const purchasedAppProducts = _intersection(purchasesCodes, productCodes);
    switch (page) {
      case ROUTES.FAREWELL_OFFER:
        return !purchasedAppProducts.length && mngSubFarewellOfferPage.enabled && paymentSystem === PaymentSystem.SOLIDGATE;
      case ROUTES.REASON:
        return mngSubReasonPage.enabled;
      case ROUTES.BENEFITS:
        return mngSubBenefitsPage.enabled;
      case ROUTES.OFFER_FREE_PERIOD:
        const product = mngSubOfferFreePeriodPage[currentProduct?.currency!] ?? mngSubOfferFreePeriodPage.USD
        return product.productId !== currentProduct?.id && mngSubOfferFreePeriodPage.enabled && paymentSystem === PaymentSystem.SOLIDGATE;
      case ROUTES.OFFER_CHEAP_PRODUCT:
        const cheapProduct = mngSubOfferCheapProductPage[currentProduct?.currency!] ?? mngSubOfferCheapProductPage.USD
        return mngSubOfferCheapProductPage.enabled && cheapProduct.productId !== currentProduct?.id && paymentSystem === PaymentSystem.SOLIDGATE;
      case ROUTES.PROGRESS:
        return mngProgressPage.enabled
      default:
        return true
    }
  };

  const navigateScreen = (mode: 'prev' | 'next') => (isReplace?: boolean) => {
    let currentPage = match.pathname;
    let isPresent = false;
    let nextPage = '';

    while (!isPresent) {
      nextPage = mode === 'prev' ? getPrevPage(currentPage) : getNextPage(currentPage)
      isPresent = isPageAvailable(nextPage);
      if (!isPresent) {
        currentPage = nextPage
      }
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    const params = queryString.parse(window.location.search);
    const search = queryString.stringify(params);

    if (isReplace) {
      navigate({
        pathname: nextPage,
        search: search,
      },
        {
          state: {
            inFlow: true,
          },
          replace: true
        });
      return;
    }

    navigate({
      pathname: nextPage,
      search: search,
    },
      {
        state: {
          inFlow: true,
        }
      });
  }

  return { navigateNextScreen: navigateScreen('next'), navigatePrevSceen: navigateScreen('prev') }
}