import Analytics from '@web-solutions/module-analytics';
import { trackCustom, trackSubscribe } from 'src/fbAnalytics';

export const trackPurchase = (data) => {
  debugger
  /* 
  {
      "price_id": "1_week_non_trial",
      "email": "xx@xx.xx",
      "first_name": "DEV",
      "last_name": "DEV",
      "method": "card",
      "paymentSystem": "recurly",
      "token_id": "lCABGxuNN_Pm0TDu2aOKUg",
      "resource_type": "PurchasesSubscription",
      "transaction_id": "udsbp24ax2dq",
      "plan_name": "1_week_non_trial",
      "currency": "USD",
      "amount": 14.99,
      "customer_id": "udsbozg4nlpz",
      "created_at": "2024-02-09T15:34:05Z",
      "trial_start_at": null,
      "trial_end_at": null,
      "expire_at": null,
      "canceled_at": null,
      "state": "active",
      "is_active": true,
      "provider": "recurly",
      "tracks": {
          "subtruck": {}
      }
  }
  */
  
  Analytics.trackEvent('purchase', 'success', data);
  Analytics.trackEvent('purchase', 'subscription_success', data);
  trackSubscribe(data.amount, data.currency);

  Analytics.trackEvent('Subscribe', data?.trial_start_at ? 'with_trial' : 'without_trial', data);
  trackCustom(data?.trial_start_at ? 'Subscribe_with_trial' : 'Subscribe_without_trial', data);
}