import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';
import { tm, T } from '@web-solutions/module-localization';

import { useRemoteConfig } from 'core/hooks/use-remote-config';

import { Title, Section } from 'core/ui-elements';

import TrialReminder from 'core/payment/components/trial-reminder';
import MoneyBackDescription from '../money-back-description';
import { selectSpecialOfferEndDate } from 'core/store/billing/selectors';

import FixedButton from '../fixed-button';
import InjectedPaymentMethodSwitch from '@web-solutions/core/summary/injected-payment-method/components/switch';

import { PaymentSafe } from '../payment-safe';

import PolicyCheckbox from './policy-check-box';
import { Item } from './item';

import classes from './style.module.scss';

const Plans = ({
  activeProduct,
  products,
  showTerms = true,
  showButton = true,
  onProductClick,
  onButtonClick,
  onPayPalSubmit,
  subtitle,
}) => {
  const plansSectionId = 'plans';
  const productsListId = 'products-list';

  const {
    policyLinks,
    policyCheckboxChecked,
    buttonsConfigs,
    productsTitle,
    showTermsAtPlans,
    showPaymentSafeAtPlans,
    isInjectedPaymentMethodOnPage,
    isTrialPeriodReminder,
    specialOffer,
  } = useRemoteConfig();

  const specialOfferEndDate = useSelector(selectSpecialOfferEndDate);

  const handleItemClick = useCallback(
    (selectedProduct) => {
      Analytics.trackEvent('product', 'selected', { selectedProductId: selectedProduct.id });
      onProductClick(selectedProduct);
    },
    [onProductClick],
  );

  const handleFixedButtonClick = (type, isVisible) => {
    onButtonClick(type, isVisible);
  };

  const renderItems = useCallback(
    () => products.map((product) => (
      <Item
        key={product.id}
        isActive={activeProduct.id === product.id}
        onClick={handleItemClick}
        product={product}
      />
    )),
    [products, activeProduct.id, handleItemClick],
  );

  const title = specialOfferEndDate ? <T k={'core.special_offer.products'} tOptions={{ discount: specialOffer.discount }} /> : tm(productsTitle);

  const classContainer = classNames(classes.container);

  return (
    <Section className={classNames(classes.wrap)} id={plansSectionId}>
      {!!title && <Title level="h2">{title}</Title>}
      {subtitle}
      <div className={classContainer}>
        <ul className={classes.productsList} id={productsListId}>{renderItems()}</ul>
      </div>

      {
        isInjectedPaymentMethodOnPage && (
          <InjectedPaymentMethodSwitch />
        )
      }

      {
        (isTrialPeriodReminder && !activeProduct.isOneTimePurchase) && <TrialReminder className={classes.reminder} />
      }

      {showButton &&
        <FixedButton
          activeProduct={activeProduct}
          buttonsConfigs={buttonsConfigs}
          onClick={handleFixedButtonClick}
          id={productsListId}
          onPayPalSubmit={onPayPalSubmit}
          onCardClick={onButtonClick}
        />
      }

      <PolicyCheckbox policyLinks={policyLinks} checkboxChecked={policyCheckboxChecked} />

      <PaymentSafe isModal={false} />
      
      <MoneyBackDescription />

    </Section>
  );
};

Plans.propTypes = {
  products: PropTypes.array.isRequired,
  activeProduct: PropTypes.object.isRequired,
  showTerms: PropTypes.bool,
  showButton: PropTypes.bool,
  onProductClick: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onApplePaySubmit: PropTypes.func,
  onPayPalSubmit: PropTypes.func
};

export default Plans;
