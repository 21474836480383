import React from 'react';

import { QuizImage } from 'src/components/common/quiz-image';
import { QuizText } from 'src/components/common/quiz-text';
import { QuizButton } from 'src/components/connected/quiz-button';

import { BlurContainer } from '../blur-container';

import './style.scss';

export const QuizScreen = ({onButtonClick, gradientTop = true, beforeFooter = false}) => (
  <div className={'subscription-quiz-screen'}>
    <BlurContainer gradientTop={gradientTop} beforeFooter={beforeFooter} />
    <div className="quiz-frame">
      <QuizImage src={"/assets/QuizContent/Images/sub.jpg"} hidden onClick={onButtonClick} />
      <QuizText>
        Your girl is ready
        <br/>
        and want to continue
      </QuizText>
      <QuizButton title={"Continue"} onClick={onButtonClick}/>
    </div>
  </div>
);
