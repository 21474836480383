import "./style.css";

import React from "react";

export const QuizText = React.memo(({
                                         children,
                                         className = ''
                                       }) => {
  return (
    <div className={`quiz-text ${className}`}>
        {children}
    </div>
  );
})