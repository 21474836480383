import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './style.css';

import Analytics from '@web-solutions/module-analytics';
import {
  completeStepAndNavigate,
} from '@web-solutions/giantcrush/src/store/progress/actions';

export const QuizButton = React.memo(({
                                        stepKey = '',
                                        title = 'Continue',
                                        onClick,
                                        onContinue,
                                        className = '',
                                      }) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const doCompete = () => {
    Analytics.trackEvent('step', 'completed', {
      id: stepKey
    });
    dispatch(completeStepAndNavigate(stepKey, 1));
  };

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
      return;
    }

    if (typeof onContinue === 'function') {
      if (disabled) {
        return;
      }
      setDisabled(true);
      onContinue().then(doCompete);
      return;
    }

    doCompete();
  };

  return (
    <>
      <div className={`quiz-btn-container ${className}`}>
        <button className='quiz-btn' onClick={handleClick}>
          {title}
        </button>
      </div>
    </>
  );
});
