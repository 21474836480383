import React, { useMemo } from 'react';
import classNames from 'classnames';
// @ts-ignore
import Modal from 'react-modal';

import { t } from '@web-solutions/module-localization';

import { Title, Text, LinkButton } from 'core/ui-elements';
import { Button } from 'core/ui-elements';
import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { Theme } from 'core/constants/theme';
import { useThemeContext } from 'core/hooks/use-theme-context';

import { ReactComponent as IconCancel } from './icons/cancel.svg';
import { IMAGES } from './images';

import classes from './style.module.scss';
import { InsufficientFunds } from './components/302';
import { InvalidData } from './components/201-208-209';
import { PaymentAmountLimit } from './components/303';
import { SubscriptionLocked } from './components/214';
import { CardIssuer } from './components/003-306-403';
import { TokenNotFound } from './components/701';
import { CardIssuerWithImage } from './components/001-002-101-213-301-310-40x-501-602';
import { CardIssuerLaw } from './components/304-305-308-408-601';
import { CardBrandNotSupported } from './components/307';
import { CardIssuerInvalidFlow } from './components/211-212-309-502-503-508-707';
import { CardIssuerEmailFooter } from './components/2xx-5xx-7xx';
import { getContentByCode } from './utils/get-content-by-code';

const ERRORS_CONTENT = {
  insufficientFunds: InsufficientFunds,
  invalidData: InvalidData,
  paymentAmountLimit: PaymentAmountLimit,
  subscriptionLocked: SubscriptionLocked,
  cardIssuer: CardIssuer,
  tokenNotFound: TokenNotFound,
  cardIssuerWithImage: CardIssuerWithImage,
  cardIssuerLaw: CardIssuerLaw,
  cardBrandNotSupported: CardBrandNotSupported,
  cardIssuerInvalidFlow: CardIssuerInvalidFlow,
  cardIssuerEmailFooter: CardIssuerEmailFooter,
}

const images = {
  'card': IMAGES.CARD_BG,
  'billing': IMAGES.BILLING_BG,
}

const images_dark = {
  'card': IMAGES.DARK_CARD_BG,
  'billing': IMAGES.DARK_BILLING_BG,
}

interface Props {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  statusCode?: string | number,
  withStatusCode?: boolean,
  buttonTitle?: string,
  tKey?: string,
  onSkipClick?: () => void;
}

const ErrorPopup: React.FC<Props> = ({
  visible,
  onClose,
  onSubmit,
  buttonTitle,
  statusCode,
  tKey = 'core.subscription.error_popup',
  onSkipClick,
}) => {

  const { errorPopup } = useRemoteConfig();
  const { theme } = useThemeContext();
  const isDark = theme === Theme.dark ? true : false;

  const code = useMemo(() => {
    const isInsufficentFunds = statusCode === '3.02' || statusCode === '302' || statusCode === 302 || statusCode === 3.02
    if (statusCode && isInsufficentFunds) {
      return `302`
    } else {
      return errorPopup?.type
    }
  }, [errorPopup?.type, statusCode])

  const Component = ERRORS_CONTENT[getContentByCode(statusCode)]

  return (
    <Modal
      isOpen={visible}
      className={classNames(classes.popup, { [classes.dark]: isDark })}
      overlayClassName={classNames(classes.overlay, { [classes.dark]: isDark })}
    >
      <IconCancel onClick={onClose} className={classes.close} />
      {errorPopup.isDetailedErrors ?
        <Component onClick={onSubmit} />
        :
        <>
          <img
            className={classes.img}
            src={isDark ? images_dark[errorPopup?.type].src : images[errorPopup?.type].src}
            srcSet={isDark ? images_dark[errorPopup?.type].srcSet : images[errorPopup?.type].srcSet}
            alt='background'
          />
          <Title className={classes.title}>
            {t(`${tKey}.${code}.title`)}
          </Title>
          <Text className={classes.text}>
            {t(`${tKey}.${code}.text`)}
          </Text>
          <Button
            onClick={onSubmit}
            flare={false}
            iconRight={false}
            className={classes.button}
            title={buttonTitle || t(`${tKey}.${code}.button`)}
          />
          {onSkipClick && <LinkButton className={classes.button} onClick={onSkipClick}>
            {t(`${tKey}.${code}.button_later`)}
          </LinkButton>}
        </>
      }
    </Modal>
  )
};

export default ErrorPopup;
