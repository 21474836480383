import _filter from 'lodash/filter';
import { useState, useMemo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import Plans from '@web-solutions/core/summary/plans';
import { Button } from '@web-solutions/core/ui-elements';
import PaymentDescription from '@web-solutions/core/payment/components/payment-description';
import Analytics from '@web-solutions/module-analytics';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { LString, ProductConfig } from '@web-solutions/core/constants/remote-config';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import ErrorPopup from '@web-solutions/core/payment/components/error-popup';
import { PaymentProcessor, PaymentProcessorRef } from '@web-solutions/core/payment';
import { Purchase } from '@web-solutions/core/interfaces/billing';
import { selectOrderDetails, selectPaymentSystem } from '@web-solutions/core/store/billing/selectors';
import { PaymentSystem } from '@web-solutions/react-billing';

import { changePlan, selectProduct, useSliceDispatch } from '../../slice';

import { Header } from '../header';
import { ReactComponent as CloseIcon } from '../icons/close-icon.svg';
import { t, tm } from '../../../localization';
import { SuccessModal } from '../success-modal';

import classes from './style.module.scss';

const tKey = 'manage.plan';

interface PlansPageProps {
  category: string,
  withCloseIcon?: boolean,
  title?: LString,
  buttonText?: LString,
  errorPopupButton?: string,
  withBackButton?: boolean,
  onBackClick: () => void,
  onCloseClick?: () => void,
  onSuccessClose?: (purchase?: Purchase) => void,
  onSkipClick?: () => void,
  onPaymentClose?: () => void,
}

export const PlansContent: React.FC<PlansPageProps> = ({
  category,
  withBackButton,
  title,
  buttonText,
  errorPopupButton,
  withCloseIcon,
  onBackClick,
  onCloseClick,
  onSuccessClose,
  onPaymentClose,
  onSkipClick,
}) => {
  const ref = useRef<PaymentProcessorRef>(null);
  const dispatch = useSliceDispatch();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false)
  const [statusCode, setStatusCode] = useState<string | undefined>();

  const product = useSelector(selectProduct);
  const orderDetails = useSelector(selectOrderDetails);
  const paymentSystem = useSelector(selectPaymentSystem);
  const products = useSelector((state: any) => state.billing.products);
  const { showTermsAtPlans } = useRemoteConfig();

  const isChangeSkipped = paymentSystem === PaymentSystem.PADDLE || !product

  const options = useMemo(() => {
    let productsWithActualCurrency = _filter(products, p => p.id !== product?.id && p.currency === product?.currency);
    if (productsWithActualCurrency.length === 0) {
      productsWithActualCurrency = _filter(products, p => p.id !== product?.id && p.currency === 'USD');
    }
    return productsWithActualCurrency;
  }, [products, product]);

  const [activeProduct, setActiveProduct] = useState(options.find((p) => p.default) || options[0] || {});

  useEffect(() => {
    setActiveProduct(options.find((p) => Object.values(p).includes(activeProduct?.id)) || options.find((p) => p.default) || options[0] || activeProduct);
  }, [activeProduct, options, setActiveProduct]);

  const handleProductClick = (p: ProductConfig) => {
    setActiveProduct(p);
    Analytics.trackEvent(category, EVENT_ACTION.CLICK, { productId: p.id });
  };

  const handleChangeClick = () => {
    Analytics.trackEvent(category, 'submit', { productId: activeProduct.id });
    dispatch(changePlan({ productId: activeProduct.id }))
      .unwrap()
      .then(() => {
        Analytics.trackEvent(category, EVENT_ACTION.SUCCESS, { productId: activeProduct.id });
        setSuccess(true);
      })
      .catch((e) => {
        Analytics.trackEvent(category, EVENT_ACTION.ERROR, { productId: activeProduct.id, message: e?.message, code: e?.code });
        setError(true);
        setStatusCode(e?.statusCode);
        throw e;
      });
  };

  const handleCloseErrorClick = () => {
    setError(false);
  }

  const handleSubmitError = () => {
    setError(false)
    ref.current?.showPaymentPopup();
  }

  const handlePaymentSuccess = () => {
    setSuccess(true)
  }

  return <div className={classes.wrap}>
    <Header
      title={tm(title, `${tKey}.title`)}
      subtitle={t(`${tKey}.subtitle`)}
      onBackClick={onBackClick}
      className={classes.header}
      withBackButton={withBackButton}
    />
    {withCloseIcon && <button className={classes.close} onClick={onCloseClick}>
      <CloseIcon />
    </button>}

    <Plans
      activeProduct={activeProduct}
      products={options}
      showTerms={false}
      showButton={false}
      onProductClick={handleProductClick}
      onButtonClick={handleProductClick}
    />

    <Button
      onClick={isChangeSkipped ? () => ref.current?.showPaymentPopup() : handleChangeClick}
      type="button"
      className={classes.btn}
      title={tm(buttonText, `${tKey}.btn`)}
    />

    {showTermsAtPlans &&
      <div className={classes.description}>
        <PaymentDescription
          activeProduct={activeProduct}
          isShort />
      </div>
    }

    <SuccessModal
      category={`${category}_success_modal`}
      isOpen={success}
      tKey={`${tKey}.success_modal`}
      onClose={onSuccessClose}
    />

    <PaymentProcessor
      orderDetails={orderDetails}
      activeProduct={activeProduct}
      onSuccess={handlePaymentSuccess}
      onClose={onPaymentClose}
      ref={ref}
    />

    <ErrorPopup
      visible={!!error}
      onSubmit={handleSubmitError}
      statusCode={statusCode}
      buttonTitle={errorPopupButton}
      onClose={handleCloseErrorClick}
      onSkipClick={onSkipClick}
    />
  </div>
}