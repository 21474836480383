import React from 'react';
import classNames from 'classnames';

import { T } from '@web-solutions/module-localization';

import classes from './style.module.scss';

export interface Props {
  isModal: boolean;
}

export const PaymentSafe: React.FC<Props> = ({ isModal = true }) => (
  <>
    {
      isModal ? (
        <span className={classNames(classes.content, classes.modalContent)}>
          <span className={classNames(classes.icon, classes.modalIcon)}>
            <T k={'core.payment_safe'} />
          </span>
        </span>
      ) : (
        <>
          <span className={classNames(classes.content, classes.lightContent)}>
            <span className={classNames(classes.icon, classes.lightIcon)}>
              <T k={'core.payment_safe'} />
            </span>
          </span>
          <span className={classNames(classes.content, classes.darkContent)}>
            <span className={classNames(classes.icon, classes.darkIcon)}>
              <T k={'core.payment_safe'} />
            </span>
          </span>
        </>
      )
    }
  </>
);
