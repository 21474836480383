import './App.css';
import { StrictMode } from 'react';
import configureStore from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { RootView } from './screens';

import { initLocalization } from './localization';

import Analytics from '@web-solutions/module-analytics';

import { ThemeContextProvider } from 'core/hooks/use-theme-context';
import { Theme } from 'core/constants/theme';

import { getMagnusToken } from 'src/store/app/utils';

import packageInfo from '../package.json';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export default function App() {
  const pType = window.location.pathname.split('/')[1];
  const { store, persistor } = configureStore();

  Analytics.init(getMagnusToken(), packageInfo.version);

  if (pType !== 'terminate') {
    (async () => {
      const { load } = await import('./store/app/actions');
      store.dispatch(load());
    })();
  }
  else {
    initLocalization();
  }

  return (
      <StrictMode>
        <ThemeContextProvider value={{ theme: Theme.light }}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <RootView />
              <ToastContainer position='bottom-center' autoClose={5000} closeOnClick />
            </PersistGate>
          </Provider>
        </ThemeContextProvider>
      </StrictMode>
  );
}
