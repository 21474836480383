// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nOK7Jszry_MGGcFrPxvG{display:none}", "",{"version":3,"sources":["webpack://./../../packages/core/payment/components/paddle-form/style.module.scss"],"names":[],"mappings":"AACA,sBACE,YAAA","sourcesContent":["$colorTheme: artpix;\n.hidden {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": "nOK7Jszry_MGGcFrPxvG"
};
export default ___CSS_LOADER_EXPORT___;
