// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rPOhje7kdBuc_WkAWg11{font-size:12px;color:#6a6577;margin:0 0 5px 0;text-align:left;line-height:14.63px}.iO2u7W05oMZ87EVCo2yh{width:100%;display:flex;flex-direction:column;align-items:flex-start;margin:15px 0 0;position:relative;z-index:1}.eb6wQ5ri8qk8Pm665hbX{display:flex}.f8Q_D5YSUPtjOT84kISA{margin:0 0 0 30px}.KxMvg06PQGJ5LAj0sPfu{text-decoration:underline;margin-right:5px}.CEvcqBQh6exmkdGLSWZv{margin-right:5px}", "",{"version":3,"sources":["webpack://./src/components/overrides/plans/policy-check-box/style.module.scss"],"names":[],"mappings":"AAGA,sBACE,cAAA,CACA,aAAA,CACA,gBAAA,CACA,eAAA,CACA,mBAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,eAAA,CACA,iBAAA,CACA,SAAA,CAEA,sBACE,YAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,yBAAA,CACA,gBAAA,CAGF,sBACE,gBAAA","sourcesContent":["$colorTheme: artpix;\n@import 'src/styles';\r\n\r\n.text {\r\n  font-size: 12px;\r\n  color: #6A6577;\r\n  margin: 0 0 5px 0;\r\n  text-align: left;\r\n  line-height: 14.63px;\r\n}\r\n\r\n.confirmation {\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n  margin: 15px 0 0;\r\n  position: relative;\r\n  z-index: 1;\r\n\r\n  &__label {\r\n    display: flex;\r\n  }\r\n\r\n  &__text {\r\n    margin: 0 0 0 30px;\r\n  }\r\n\r\n  &__link {\r\n    text-decoration: underline;\r\n    margin-right: 5px;\r\n  }\r\n\r\n  &__item {\r\n    margin-right: 5px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "rPOhje7kdBuc_WkAWg11",
	"confirmation": "iO2u7W05oMZ87EVCo2yh",
	"confirmation__label": "eb6wQ5ri8qk8Pm665hbX",
	"confirmation__text": "f8Q_D5YSUPtjOT84kISA",
	"confirmation__link": "KxMvg06PQGJ5LAj0sPfu",
	"confirmation__item": "CEvcqBQh6exmkdGLSWZv"
};
export default ___CSS_LOADER_EXPORT___;
