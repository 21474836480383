import React from 'react';
import classNames from 'classnames';

import { T, } from '@web-solutions/module-localization';

import { ReactComponent as Icon } from './icon.svg';
import classes from './style.module.scss';

interface SoldLineProps {
  isActive: boolean,
  left: number,
  total: number,
}

export const SoldLine: React.FC<SoldLineProps> = ({
  isActive,
  left,
  total,
}) => {
  return (
    <div className={classNames(classes.root, { [classes.active]: isActive })}>
      {left ? <Icon /> : <span className={classes.icon} />}
      <T
        k={'core.plans.sold'}
        tOptions={{ left, total }}
      />
    </div>
  );
};
