export const createSrcSet = (url, extension) =>
  `${url}.${extension} 1x, ${url}@2x.${extension} 2x, ${url}@3x.${extension} 3x`;

export const EXTENSION = {
  WEBP: 'webp',
  PNG: 'png',
  JPG: 'jpg',
  JPEG: 'jpeg',
  gif: 'gif',
}
