import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import classNames from 'classnames';

import { PaymentSystem } from '@web-solutions/react-billing';

import { createCustomer } from 'core/store/billing/actions';
import { Preloader } from 'core/ui-elements';

import { countries } from '../../shipping-address/data';

import classes from './style.module.scss';

/**
 * STYLE GUIDE FOR BUTTON
 * https://developer.paypal.com/docs/business/checkout/reference/style-guide
 */

const DEFAULT_PAYPAL_BUTTON_STYLES = {
  shape: 'rect',
  layout: 'vertical',
  label: 'paypal',
  color: 'gold',
  tagline: 'false',
};

const PayPalBtn = ({
  planId,
  style,
  className,
  currency,
  isDisabled,
  requiredShippingAddress,
  onSuccess,
  onClick,
  onCancel,
  onError,
}) => {
  const [{ options, isPending }, dispatchPayPal] = usePayPalScriptReducer();

  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);

  useEffect(() => {
    dispatchPayPal({
      type: 'resetOptions',
      value: {
        ...options,
        currency,
        intent: 'subscription',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, planId]);

  const handleCreateSubscription = async (data, actions) => {
    const customer = await dispatch(createCustomer({ paymentSystem: PaymentSystem.PAYPAL }));
    const idfm = queryString.parse(window.location.search).idfm;
    return actions.subscription.create({
      plan_id: planId,
      custom_id: `${customer.id}|${Date.now()}|${idfm}`, // Date.now works like hash, just to make id uniq.
    });
  };

  const handleShippingChange = async (data, actions) => {
    if (!requiredShippingAddress || countries[data?.shipping_address?.country_code]) {
      actions.resolve();
    } else {
      actions.reject();
    }
  };

  const handleClick = (e) => {
    setPending(true)
    onClick(e)
  }

  const handleCancel = () => {
    setPending(false)
    onCancel()
  }

  const handleError = (e) => {
    setPending(false)
    onError(e)
  }

  const handleApprove = (e) => {
    setPending(false)
    onSuccess(e)
  }

  if (isPending) return null;

  return (
    <>
      <PayPalButtons
        className={classNames(classes.container, className)}
        style={style}
        disabled={isDisabled}
        fundingSource={undefined}
        createSubscription={handleCreateSubscription}
        onClick={handleClick}
        onCancel={handleCancel}
        onApprove={handleApprove}
        onError={handleError}
        onShippingChange={handleShippingChange}
      />
      {pending && <Preloader />}
    </>
  );
};

PayPalBtn.propTypes = {
  style: PropTypes.object,
  planId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

PayPalBtn.defaultProps = {
  style: DEFAULT_PAYPAL_BUTTON_STYLES,
  isDisabled: false,
};

export default PayPalBtn;
