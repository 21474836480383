import { type Nullable } from '@web-solutions/core/constants/remote-config';

import {
  INITIAL_CONFIG as BASE_INITIAL_CONFIG,
  type RemoteConfig as BaseRemoteConfig,
} from '@web-solutions/base-app/constants/remote-config';

import {AppLinks} from "../../../../packages/module-attribution";
import {SubscriptionScreenVersion} from "src/screens/subscription/version";

export interface RemoteConfig extends BaseRemoteConfig {
  appLinks: AppLinks,
  gamePreviewImage: string,
  gamePreviewAppIcon: string,
  gamePreviewURL: string,
  gamePreviewButtonText: string,
  subscriptionScreenVersion: SubscriptionScreenVersion,
  quizScreensOrder: Array<string>
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...BASE_INITIAL_CONFIG,

  appLinks: {},
  gamePreviewImage: `${process.env.REACT_APP_PUBLIC_URL}/assets/Sprites/GamePreview.webp`,
  gamePreviewAppIcon: `${process.env.REACT_APP_PUBLIC_URL}/assets/Sprites/AppIcon.png`,
  gamePreviewURL: "https://giantcrush.onelink.me/KlhG/premium",
  gamePreviewButtonText: "Download now >",

  subscriptionScreenVersion: SubscriptionScreenVersion.V2,

  quizScreensOrder: [
    "HOME_VISIT",
    "ATMOSPHERE",
    "HOME_VISIT_ACTION",
    "HOME_VISIT_ACTION_RESULT",
    "HOME_VISIT_SAY",
    "HOME_VISIT_SAY_RESULT",
    "HOME_VISIT_UNDRESS",
    "HOME_VISIT_KISS",
    "HOME_VISIT_TV",
    "HOME_VISIT_TV_GIANT",
    "HOME_VISIT_SAVE_CITY",
    "FIGHT_PREPARATION",
    "FIGHT_START",
    "FIGHT_GIANT_PART",
    "FIGHT_GIANT_PART_RESULT",
    "FIGHT_CONTINUE",
    "FIGHT_PREPARATION_EQUIP_START",
    "FIGHT_PREPARATION_EQUIP_CHOOSE",
    "FIGHT_PREPARATION_STATS",
    "FIGHT_PREPARATION_EQUIP_PROGRESS",
    "FIGHT_PREPARATION_EQUIP_DONE",
    "FINAL_SCREEN",
    "GAMEPLAY_PREVIEW",
    "YOUR_EMAIL"
  ],
};

export type FullConfig = Nullable<Partial<RemoteConfig>>;
