import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { ButtonPlacementType } from 'core/payment/constants';
import InjectedPaymentMethodButton from 'core/summary/injected-payment-method/components/button';
import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import TrialsLeft from 'core/payment/components/trials-left';

import { getButtonTitleByConfig } from 'core/utils/button-configurator';

import classes from './style.module.scss';
import { MajorButton } from '../button/major';

const FixedButton = ({
  activeProduct,
  buttonsConfigs,
  onClick,
  onPayPalSubmit,
}) => {
  const buttonConfigs = buttonsConfigs[ButtonPlacementType.PLANS] || {};
  const { isInjectedPaymentMethodOnPage } = useSelector(remoteConfigSelector);

  const title = getButtonTitleByConfig(buttonConfigs, 'core.get_my_plan');

  const handleButtonClick = (e) => {
    Analytics.trackEvent('products_button', 'click');
    e.preventDefault();
    onClick(ButtonPlacementType.PLANS);
  };

  return (
    <div className={classNames(classes.content, classes.buttonMargin)}>
      <TrialsLeft type='badge' />
      <MajorButton text={title} onClick={handleButtonClick} />
      {
        isInjectedPaymentMethodOnPage && (
          <InjectedPaymentMethodButton
            activeProduct={activeProduct}
            onPayPalSubmit={onPayPalSubmit}
            onCardClick={onClick}
          />
        )
      }
    </div>
  );
};

FixedButton.propTypes = {
  activeProduct: PropTypes.object.isRequired,
  buttonsConfigs: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onApplePaySubmit: PropTypes.func,
  onPayPalSubmit: PropTypes.func,
};

FixedButton.defaultProps = {
  id: '',
};

export default FixedButton;
