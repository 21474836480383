import { PaymentSystem, Product as ProductDetails, Period } from '@web-solutions/react-billing/constants';

import { REDIRECT_TO_CHROME } from 'core/utils/redirect-to-chrome';

import { PriceAfterTrialType, ButtonPlacementType, ButtonBehaviorType, CardFormLayout, } from 'core/payment/constants';

import { RemoteConfig as ManageRemoteConfig, INITIAL_CONFIG as MANAGE_INITIAL_CONFIG } from '../../manage-subscription/src/manage/constants/remote-config'

import { ModeApplePay, ModeGooglePay, ModePayPal, InertialApplePay, ErrorPopupType } from './billing';

interface PolicyLinks {
  termsOfUse: string,
  privacy: string,
  moneyBack: string,
  billingTerms: string,
  address?: string,
  phone?: string,
  legalEntity?: string,
}

const POLICY_LINKS: PolicyLinks = {
  termsOfUse: '/terms-of-use',
  privacy: '/privacy-policy',
  moneyBack: '/money-back-policy',
  billingTerms: '/billing-terms',
};

const BTNS_CONFIGS: {
  [key in ButtonPlacementType]: {
    behavior?: ButtonBehaviorType,
    title: LString,
    uppercaseTitle: boolean,
    enabledFlareAnimation?: boolean,
  }
} = {
  [ButtonPlacementType.PAYMENT]: {
    title: '',
    uppercaseTitle: false,
  },
  [ButtonPlacementType.TOP]: {
    behavior: ButtonBehaviorType.SCROLL,
    title: '',
    uppercaseTitle: false,
    enabledFlareAnimation: true,
  },
  [ButtonPlacementType.PLANS]: {
    behavior: ButtonBehaviorType.MODAL,
    title: '',
    uppercaseTitle: false,
    enabledFlareAnimation: true,
  },
  [ButtonPlacementType.FIXED]: {
    behavior: ButtonBehaviorType.SCROLL,
    title: '',
    uppercaseTitle: false,
    enabledFlareAnimation: true,
  },
};

export type ProductConfig = {
  id?: string,
  paypalPlanId?: string,
  recurlyId?: string,
  solidgateId?: string,
  paddleId?: string,
  title?: LString,
  name?: LString,
  subTitle?: LString,
  showAfterTrialPrice?: boolean,
  badgeTitle?: LString,
  badgeCentered?: boolean,
  period?: Period,
  descriptionText?: LString,
  discount?: string,
  discountPrice?: string,
  default?: boolean,
  textLineThrough?: LString,
  perWeekTitle?: LString,
  perWeekText?: LString,
  afterTrialText?: LString,
  previousPerWeekTitle?: LString,
  sold?: { left: number, total: number, hiddenSubTitles?: boolean },
  smallBadges?: string[],
}

export type LString = string | Title;

type Title = {
  en: string,
  de?: string,
  es?: string,
  fr?: string,
  pt?: string,
}

type ProductsDetails = ProductDetails[];

type AdditionalFields = {
  email?: {
    label: string,
    type: string,
    isRequired: boolean,
  },
  cardholder?: boolean,
  zip?: boolean,
};

export enum InjectedPaymentMethod {
  APPLEPAY = 'applepay',
  PAYPAL = 'paypal',
  CARD = 'card',
  GOOGLEPAY = 'googlepay',
  APPLEPAY_PAYPAL = 'applepay_paypal',
  GOOGLEPAY_PAYPAL = 'googlepay_paypal',
}

export enum PaymentModalType {
  BASE = 'base',
  BOTTOM_INJECTED = 'bottom_injected',
  TOP_INJECTED = 'top_injected',
}

export type PriceTypes = 'today' | 'week' | 'day'

export type Nullable<T> = { [K in keyof T]: T[K] | null };

export interface RemoteConfig extends ManageRemoteConfig {
  isCvvTooltip: boolean,
  displayVAT: boolean,
  delayFaceCaptureWithAnalysis: boolean,
  isTakePhotoAfterFaceAnalysis: boolean,
  isOldPaymentDescription: boolean,
  showTimerInPlanBadge: boolean,
  paymentProject: string,
  paymentSystem: PaymentSystem,
  paymentSystemReserve: PaymentSystem | '',
  products: ProductConfig[],
  productsDetails: ProductsDetails,
  additionalFields: AdditionalFields,
  policyLinks: PolicyLinks,
  address: string,
  policyCheckboxChecked: boolean,
  buttonsConfigs: typeof BTNS_CONFIGS,
  priceAfterTrialType: PriceAfterTrialType,
  productsTitle: LString,
  showAfterTrialPrice: boolean,
  showPricesBlock: boolean,
  showPriceDiscount: boolean,
  openPaymentModalOnProductClick: boolean,
  isShowSafeCheckout: boolean,
  discountTime: number,
  modePayPal: ModePayPal,
  modeApplePay: ModeApplePay,
  inertialApplePay: InertialApplePay,
  modeGooglePay: ModeGooglePay,
  cardFormCollapsed: boolean,
  cardFormLayout: CardFormLayout,
  showPaymentSafeAtPlans: boolean,
  showTermsAtPlans: boolean,
  requiredShippingAddress: boolean,
  totalPriceType: PriceTypes,
  isInjectedPaymentMethodOnPage: boolean,
  paymentModalType: PaymentModalType,
  injectedPaymentMethods: InjectedPaymentMethod[],
  defaultInjectedPaymentMethod: InjectedPaymentMethod,
  quizPaymentModalFullMode: boolean,
  isPaymentModalShowAfterQuiz: boolean,
  errorPopup: {
    enabled: boolean,
    buttonTitle: string,
    type: ErrorPopupType,
    isDetailedErrors: boolean,
  },
  isTrialPeriodReminder: boolean,
  trialsLeft: {
    enabled: boolean,
    time: number,
  },
  specialOffer: {
    enabled: boolean,
    time: number,
    discount: number,
    title?: LString,
    showAfterCloseAmount: number,
    products: ProductConfig[],
  },
  redirectToChrome: REDIRECT_TO_CHROME,
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...MANAGE_INITIAL_CONFIG,
  isCvvTooltip: false,
  displayVAT: false,
  delayFaceCaptureWithAnalysis: true,
  isTakePhotoAfterFaceAnalysis: true,
  isOldPaymentDescription: false,
  showTimerInPlanBadge: false,
  paymentProject: '',
  paymentSystem: PaymentSystem.RECURLY,
  paymentSystemReserve: '',
  products: [],
  productsDetails: [],
  additionalFields: {},
  policyLinks: POLICY_LINKS,
  address: 'A.Gustaicio g.23-255a, Vilnius zip: LT-02188 Lietuvos Respublika',
  policyCheckboxChecked: true,
  buttonsConfigs: BTNS_CONFIGS,
  priceAfterTrialType: PriceAfterTrialType.FULL,
  productsTitle: { en: '' },
  showAfterTrialPrice: true,
  showPricesBlock: true,
  showPriceDiscount: true,
  openPaymentModalOnProductClick: true,
  isShowSafeCheckout: true,
  discountTime: 120,
  modePayPal: ModePayPal.ENABLED,
  modeApplePay: ModeApplePay.ENABLED,
  inertialApplePay: InertialApplePay.DISABLED,
  modeGooglePay: ModeGooglePay.DISABLED,
  cardFormCollapsed: false,
  cardFormLayout: CardFormLayout.COMPACT,
  showPaymentSafeAtPlans: false,
  showTermsAtPlans: false,
  totalPriceType: 'today',
  requiredShippingAddress: false,
  paymentModalType: PaymentModalType.BASE,
  isInjectedPaymentMethodOnPage: false,
  defaultInjectedPaymentMethod: InjectedPaymentMethod.CARD,
  injectedPaymentMethods: [InjectedPaymentMethod.APPLEPAY, InjectedPaymentMethod.PAYPAL, InjectedPaymentMethod.CARD],
  quizPaymentModalFullMode: false,
  isPaymentModalShowAfterQuiz: false,
  errorPopup: {
    enabled: false,
    buttonTitle: '',
    type: ErrorPopupType.CARD,
    isDetailedErrors: true,
  },
  isTrialPeriodReminder: false,
  trialsLeft: {
    enabled: false,
    time: 5000,
  },
  specialOffer: {
    enabled: false,
    time: 100,
    discount: 50,
    products: [],
    showAfterCloseAmount: 1,
  },
  redirectToChrome: REDIRECT_TO_CHROME.DISABLED,
};
