// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gmJKbnzv7Cpp9_xMvLX1{width:100%}.m1fmY754gLCLrChTFxbw{bottom:40px;position:absolute;background-color:#6a52ff;margin-left:auto;margin-right:auto;left:0;right:0}", "",{"version":3,"sources":["webpack://./../../packages/core/ui-elements/swiper/style.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CAGF,sBACE,WAAA,CACA,iBAAA,CACA,wBCTa,CDUb,gBAAA,CACA,iBAAA,CACA,MAAA,CACA,OAAA","sourcesContent":["$colorTheme: artpix;\n@import \"src/styles\";\n\n.wrap {\n  width: 100%;\n}\n\n.button {\n  bottom: 40px;\n  position: absolute;\n  background-color: $primaryColor;\n  margin-left: auto;\n  margin-right: auto;\n  left: 0;\n  right: 0;\n}\n","// colors\r\n$primaryColor: #6a52ff;\r\n$secondaryColor: #52dcaa;\r\n\r\n@if global-variable-exists(colorTheme) {\r\n  @if $colorTheme == \"aidating\" {\r\n    // colors\r\n    $primaryColor: #0E90D7;\r\n    $secondaryColor: #E76D8B;\r\n  }\r\n  @if $colorTheme == \"luvzy\" {\r\n    // colors\r\n    $primaryColor: #0d91d7;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "gmJKbnzv7Cpp9_xMvLX1",
	"button": "m1fmY754gLCLrChTFxbw"
};
export default ___CSS_LOADER_EXPORT___;
