import React, { useEffect, useRef, useMemo } from 'react';
import { useSelector, } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UAParser from 'ua-parser-js';

import { PaymentSystem } from '@web-solutions/react-billing';

import Analytics from '@web-solutions/module-analytics';

import { selectPaymentSystem } from 'core/store/billing/selectors';

import { Modal } from 'core/ui-elements';
import { Preloader } from 'core/ui-elements';

import BasePaymentModalBody from './body';
import BasePaddleModalBody from './paddle';

import { ReactComponent as IconClose } from './icons/close-icon.svg';

import classes from './style.module.scss';
import './style.scss';

const BasePaymentModal = ({
  activeProduct,
  onClose,
  onSuccess,
  onError,
  onSubmit,
  showPopup,
  options,
  showApplePay,
  showPayPal,
  showGooglePay,
  onApplePayClick,
  onApplePaySubmit,
  onGooglePayClick,
  onGooglePaySubmit,
  onPayPalSubmit,
  onUserInfoChange,
  fullMode,
  subtitle,
}) => {
  const paymentSystem = useSelector(selectPaymentSystem);

  const isPending = useSelector((state) => state.billing.pending || state.billing.orderPending);
  const isSubmitByCard = useSelector((state) => state.billing.isSubmitByCard);

  const isMobileSafari = useMemo(() => {
    const r = new UAParser().getResult();
    return r?.browser?.name === 'Mobile Safari'
  }, [])

  const ref = useRef(null);

  useEffect(() => {
    if (isSubmitByCard) {
      ref.current?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
    }
  }, [isSubmitByCard]);

  useEffect(() => {
    if (showPopup) {
      Analytics.trackEvent('modal_payment', 'open');
      ref.current?.scrollIntoView(true);
    }
  }, [showPopup]);

  useEffect(() => {
    if (showPopup) {
      if (isMobileSafari) {
        return;
      }
      document.body.classList.add('ReactModal__Body--open');
    } else {
      document.body.classList.remove('ReactModal__Body--open');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopup]);

  return (
    <Modal
      isOpen
      className={classNames(classes.modalContainer, { [classes.safariMode]: isMobileSafari, [classes.fullMode]: fullMode }, { [classes.modalContainer_active]: isPending }, { [classes.hidden]: !showPopup })}
      overlayClassName={classNames({ [classes.safariModeOverlay]: isMobileSafari, [classes.hidden]: !showPopup })}
    >
      <div className={classes.innerContainer} ref={ref}>
        <br />
        <button type="button" onClick={onClose} className={classes.close}>
          <IconClose width="24px" height="24px" />
        </button>

        {paymentSystem === PaymentSystem.PADDLE
          ? (
            <BasePaddleModalBody
              subtitle={subtitle}
              activeProduct={activeProduct}
              options={options}
              onSubmit={onSubmit}
              onSuccess={onSuccess}
              onError={onError}
              onApplePayClick={onApplePayClick}
              onApplePaySubmit={onApplePaySubmit}
              onGooglePayClick={onGooglePayClick}
              onGooglePaySubmit={onGooglePaySubmit}
              onUserInfoChange={onUserInfoChange}
            />
          ) : (
            <BasePaymentModalBody
              subtitle={subtitle}
              activeProduct={activeProduct}
              options={options}
              showApplePay={showApplePay}
              showPayPal={showPayPal}
              showGooglePay={showGooglePay}
              onSubmit={onSubmit}
              onSuccess={onSuccess}
              onError={onError}
              onApplePayClick={onApplePayClick}
              onApplePaySubmit={onApplePaySubmit}
              onGooglePayClick={onGooglePayClick}
              onGooglePaySubmit={onGooglePaySubmit}
              onPayPalSubmit={onPayPalSubmit}
              onUserInfoChange={onUserInfoChange}
            />
          )}
      </div>

      {!!isPending && <Preloader />}
    </Modal>
  );
};

BasePaymentModal.propTypes = {
  activeProduct: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  showPopup: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
  onApplePayClick: PropTypes.func.isRequired,
  onApplePaySubmit: PropTypes.func.isRequired,
  onGooglePayClick: PropTypes.func.isRequired,
  onGooglePaySubmit: PropTypes.func.isRequired,
  onPayPalSubmit: PropTypes.func.isRequired,
  onUserInfoChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default BasePaymentModal;
