import classNames from 'classnames';

import { Text } from "core/ui-elements";

import { T, t } from "../../../../../../module-localization";
import { Base } from "../base"

import classes from '../../style.module.scss'
import { IMAGES } from "../../images";

const tKey = 'core.subscription.error_popup.decline_reasons.payment_amount_limit';

interface Props {
  onClick: () => void;
}

export const PaymentAmountLimit: React.FC<Props> = ({ onClick }) => {
  const options = t(`${tKey}.options`, { returnObjects: true }) as unknown as string[]

  return <Base
    subtitle={t(`${tKey}.subtitle`)}
    withButton
    withFooter
    onClick={onClick}
  >
    <>
      {options.map((option, indx) => {
        return (
          <>
            <Text className={classes.option}>
              <div className={classes.point}>{indx + 1}</div>
              <T k={option} components={{ b: <span className={classes.highlight} /> }} />
            </Text>
            {indx === 0 && <>
              <Text className={classNames(classes.baseText, classes.amountLimitText)}>
                <T k={`${tKey}.card`} components={{ b: <span className={classes.highlight} /> }} />
              </Text>
              <img className={classes.amountLimitCardImage} src={IMAGES.IMAGE_CARD.src} srcSet={IMAGES.IMAGE_CARD.srcSet} alt="card" />
            </>}
          </>
        )
      })}
    </>
  </Base>
}