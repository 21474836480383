import { useEffect, useRef, useState } from "react";
import _throttle from 'lodash/throttle';
import _forEach from 'lodash/forEach';

interface UseFixedButtonProps {
  productsListSelector?: string;
  buttonsSelector: string;
  productSelector?: string;
}

export const useFixedButton = ({ productsListSelector, buttonsSelector, productSelector = '.product-item' }: UseFixedButtonProps) => {
  const isVisibleProducts = useRef(false);
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    const buttons = document.querySelectorAll(buttonsSelector);
    const productsList = productsListSelector && document.querySelector(`#${productsListSelector}`);
    const firstProduct = productsList && productsList.querySelector(productSelector);

    const h = _throttle(() => {
      let isVisibleStaticBtn = false;

      if (firstProduct) {
        const firstProductClientRect = firstProduct.getBoundingClientRect();
        const productListRectBottom = productsList.getBoundingClientRect().bottom;

        if (
          firstProductClientRect.top - window.innerHeight + firstProductClientRect.height <= 0
          && productListRectBottom >= 0
        ) {
          isVisibleProducts.current = true;
        } else if (isVisibleProducts.current) {
          isVisibleProducts.current = false;
        }
      }

      _forEach(buttons, (button) => {
        const rect = button.getBoundingClientRect();
        const mainBtnTopPos = rect.top + rect.height;

        isVisibleStaticBtn = isVisibleStaticBtn || (window.innerHeight > rect.bottom && mainBtnTopPos > 0);
      });

      if (isVisibleStaticBtn || isVisibleProducts.current) {
        if (fixed) {
          setFixed(false);
        }
      } else if (!fixed) {
        setFixed(true);
      }
    }, 50);

    window.addEventListener('scroll', h);
    const p = setInterval(h, 200);
    h();
    return () => {
      window.removeEventListener('scroll', h);
      clearInterval(p);
    };
  }, [fixed, setFixed, isVisibleProducts, productsListSelector, buttonsSelector, productSelector]);


  return { fixed, isVisibleProducts }
}