import React, { useState } from 'react';
import { t } from '@web-solutions/module-localization';
import { QuizImage } from "src/components/common/quiz-image";
import { QuizText } from "src/components/common/quiz-text";
import { QuizOptions } from "src/components/connected/quiz-options";
import { QuizVideo } from "src/components/connected/quiz-video";
import { QuizButton } from "src/components/connected/quiz-button";
import { QuizTutorial } from "src/components/connected/quiz-tutorial";
import { QuizEmailForm } from 'src/components/connected/quiz-email-form';
import { QuizLayout } from "src/components/common/quiz-layout";

import "./style.css";

const STATE = {
  APPEARING: 'APPEARING',
  DISAPPEARING: 'DISAPPEARING',
}

export const QuizScreen = React.memo(({screenModelId, screenModel}) => {
  const [state, setState] = useState(STATE.APPEARING);
  const handleContinue = () => {
    setState(STATE.DISAPPEARING);

    return new Promise((resolve) => {
      setTimeout(resolve, 500);
    });
  }

  let animClassSlide;
  let animClassOpacity;
  let containerStyle;

  switch (state) {
    case STATE.APPEARING:
      animClassSlide = 'animation-appear-slide';
      animClassOpacity = 'animation-appear-opacity';
      break;
    case STATE.DISAPPEARING:
      animClassSlide = 'animation-disappear-opacity';
      animClassOpacity = 'animation-disappear-opacity';
      containerStyle = {
        pointerEvents: 'none'
      }
      break;
  }

  return (
    <div className={`quiz-content`} style={containerStyle}>

      {screenModel?.content.map((entry, index) => {
        const animClassSlideDelay = STATE.APPEARING ? `${animClassSlide} animation-delay-${index} ` : animClassSlide;
        const animClassOpacityDelay = STATE.APPEARING ? `${animClassOpacity} animation-delay-${index}` : animClassOpacity;

        if ('QuizImage' in entry) {
          return <QuizImage key={index}
                            src={entry.QuizImage}
                            className={animClassOpacityDelay}/>;
        }
        if ('QuizText' in entry) {
          return <QuizText key={index}
                           className={animClassOpacityDelay}>
            {entry.QuizText.map((quizTextEntry, quizTextIndex) => {
              if (quizTextEntry?.text) {
                return <React.Fragment key={quizTextIndex}>
                  {t(quizTextEntry?.text)}
                </React.Fragment>;
              }
              if (quizTextEntry?.QuizButton) {
                return <QuizButton key={quizTextIndex}
                                   stepKey={screenModelId}
                                   title={t(quizTextEntry?.QuizButton)}/>;
              }
            })}
          </QuizText>;
        }
        if ('QuizButton' in entry) {
          return <QuizButton key={index}
                             stepKey={screenModelId}
                             title={t(entry.QuizButton)}
                             className={animClassSlideDelay}
                             onContinue={handleContinue}/>;
        }
        if ('QuizOptions' in entry) {
          return <QuizOptions key={index}
                              stepKey={screenModelId}
                              title={t(entry.QuizOptions?.title)}
                              animStartIndex={index}
                              animClassSlide={animClassSlide}
                              animClassOpacity={animClassOpacity}
                              onContinue={handleContinue}
                              answers={{
                                "A1": t(entry.QuizOptions?.answers[0]),
                                "A2": t(entry.QuizOptions?.answers[1]),
                                "A3": t(entry.QuizOptions?.answers[2]),
                              }}/>;
        }
        if ('QuizVideo' in entry) {
          return <QuizVideo key={index}
                            stepKey={screenModelId}
                            src={entry.QuizVideo.src}
                            loop={entry.QuizVideo.loop}
                            autoContinue={entry.QuizVideo.autoContinue}/>;
        }
        if ('QuizTutorial' in entry) {
          return <QuizTutorial key={index}/>;
        }
        if ('QuizEmailForm' in entry) {
          return <QuizEmailForm key={index}
                                stepKey={screenModelId}
                                text={t(entry.QuizEmailForm.text)}
                                placeholder={t(entry.QuizEmailForm.placeholder)}
                                details={t(entry.QuizEmailForm.details)}
                                validationFormat={t(entry.QuizEmailForm.validation.format)}
                                quizButton={t(entry.QuizEmailForm.QuizButton)}
                                animStartIndex={index}
                                animClassSlide={animClassSlide}
                                animClassOpacity={animClassOpacity}
                                onContinue={handleContinue} />;
        }

      })}

    </div>
  );
});
