import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface Props{
  isChecked: boolean;
  onChange: () => void;
  classNameCheckbox?: string;
}

const Checkbox: React.FC<Props> = ({ isChecked, onChange, classNameCheckbox }) => (
  <div className={classes.checkbox}>
    <input type="checkbox" id="checkbox" defaultChecked={isChecked} onChange={onChange} />
    <span className={classNames(classes.checkbox__mark, classNameCheckbox)} />
  </div>
);

export default Checkbox;
