import React from 'react';

import './style.scss';

export const QuizBackgroundImage = React.memo((
  {
    src = '',
    className = '',
  },
) => {
  const fullSrc = process.env.REACT_APP_PUBLIC_URL + src;
  return (
    <div className={`quiz-image-blur-container ${className}`}>
      <img src={fullSrc} className='quiz-image-blur'/>
    </div>
  );
});
