// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wovkbgc_JKjE3B1efR6q{width:100%;display:flex;flex-direction:column;padding:20px 16px 16px;align-items:stretch}", "",{"version":3,"sources":["webpack://./../../packages/core/ui-elements/section/style.module.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,mBAAA","sourcesContent":["$colorTheme: artpix;\n@import \"src/styles\";\r\n\r\n.wrap {\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 20px 16px 16px;\r\n  align-items: stretch;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "Wovkbgc_JKjE3B1efR6q"
};
export default ___CSS_LOADER_EXPORT___;
