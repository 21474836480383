import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { selectPaymentSystem } from 'core/store/billing/selectors';
import { InjectedPaymentMethod, PaymentModalType } from 'core/constants/remote-config';
import { CardFormLayout } from 'core/payment/constants';
import { ProductInfo } from 'core/payment/base/product-info';
import PaymentForm from 'core/payment/components/payment-form';
import TrialPrice from 'core/payment/base/product-info/components/total-price';
import ApplePay from 'core/payment/components/apple-pay';
import PayPal from 'core/payment/components/paypal';
import TrialReminder from 'core/payment/components/trial-reminder';

import InjectedPaymentMethodHeader from 'core/summary/injected-payment-method/components/header';
import InjectedPaymentMethodSwitch from 'core/summary/injected-payment-method/components/switch';
import { PaymentSafe } from 'core/containers/payment-safe';

import PaymentDescription from '../../components/payment-description';

import { ICONS } from './icons/cards';
import { ReactComponent as IconArrowDown } from './icons/icon-arrow-down.svg';
import { ReactComponent as IconCreditCard } from './icons/credit-card.svg';

import classes from './style.module.scss';

const tKey = 'core.payment_popup';

const BasePaymentModalBody = ({
  activeProduct,
  onSubmit,
  onSuccess,
  onError,
  options,
  showApplePay,
  showPayPal,
  showGooglePay,
  onApplePayClick,
  onApplePaySubmit,
  onGooglePayClick,
  onGooglePaySubmit,
  onPayPalSubmit,
  onUserInfoChange,
  subtitle,
}) => {
  const paymentSystem = useSelector(selectPaymentSystem);

  const {
    isShowSafeCheckout,
    showTermsAtPlans,
    cardFormCollapsed,
    cardFormLayout,
    requiredShippingAddress,
    isInjectedPaymentMethodOnPage,
    paymentModalType,
    isTrialPeriodReminder,
  } = useSelector(remoteConfigSelector);

  const hiddenAltMethods = isInjectedPaymentMethodOnPage && !activeProduct?.isOneTimePurchase;
  const [showCardForm, setShowCardForm] = useState(!cardFormCollapsed);

  const injectedPaymentMethod = useSelector((state) => state.billing.injectedPaymentMethod);

  const injectedApplePay = injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY || injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY_PAYPAL;
  const injectedPayPal = injectedPaymentMethod === InjectedPaymentMethod.PAYPAL || injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY_PAYPAL || injectedPaymentMethod === InjectedPaymentMethod.GOOGLEPAY_PAYPAL;
  const injectedGooglePay = injectedPaymentMethod === InjectedPaymentMethod.GOOGLEPAY || injectedPaymentMethod === InjectedPaymentMethod.GOOGLEPAY_PAYPAL;
  const injectedCard = injectedPaymentMethod === InjectedPaymentMethod.CARD;
  const injectedDouble = injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY_PAYPAL || injectedPaymentMethod === InjectedPaymentMethod.GOOGLEPAY_PAYPAL;

  const isBaseModal = paymentModalType === PaymentModalType.BASE;
  const isTopModal = paymentModalType === PaymentModalType.TOP_INJECTED;
  const isBottomModal = paymentModalType === PaymentModalType.BOTTOM_INJECTED;

  const handlePayWithCardClick = (e) => {
    e.preventDefault();
    if (showApplePay || showPayPal || showGooglePay) {
      setShowCardForm(!showCardForm);
    }
  };

  const formWrapStyle = classNames(classes.formWrap, {
    [classes.formWrapOpen]: showCardForm || injectedCard,
  });

  const expandWrapStyle = classNames(classes.expandWrap, {
    [classes.expand]: showCardForm || injectedCard,
  });

  const cardFormProps = useMemo(() => ({
    product: activeProduct,
    options,
    paymentSystem,
    formClassName: 'payment-form',
    fieldClassName: classNames('form-field', { 'form-field-compact': cardFormLayout === CardFormLayout.COMPACT }),
    onSubmit,
    onSuccess,
    onError,
    onApplePayClick,
    onApplePaySubmit,
    onGooglePayClick,
    onGooglePaySubmit,
    onUserInfoChange,
  }), [
    activeProduct,
    options,
    cardFormLayout,
    paymentSystem,
    onSubmit,
    onSuccess,
    onError,
    onApplePayClick,
    onApplePaySubmit,
    onGooglePayClick,
    onGooglePaySubmit,
    onUserInfoChange,
  ]);

  return (
    <div className={classes.contentContainer}>
      <div className={classes.wrap}>
        {
          (isBaseModal || isBottomModal) && (
            <>
              <ProductInfo
                product={activeProduct}
                subtitle={subtitle}
              />
              {
                (isTrialPeriodReminder && !activeProduct.isOneTimePurchase) ?
                  <TrialReminder className={classes.reminder} /> :
                  <div className={classes.divider} />
              }
            </>
          )
        }
        {
          (isTopModal || isBottomModal) && (
            <>
              <InjectedPaymentMethodHeader isCentralHeader={isTopModal} />
              <InjectedPaymentMethodSwitch isBaseSwitch />
            </>
          )
        }

        <div className={classNames(classes.payment, isBottomModal ? classes.row : classes.column)}>
          {
            isBottomModal && !injectedCard && (
              <TrialPrice
                textLineThrough={activeProduct.textLineThrough}
                trialPeriodPrice={activeProduct.trialPeriodPrice}
                className={classes.price}
              />
            )
          }

          {
            isTopModal && (
              <>
                <div className={classes.space} />
                <ProductInfo product={activeProduct} />
                {
                  (isTrialPeriodReminder && !activeProduct.isOneTimePurchase) && <TrialReminder className={classes.reminder} />
                }
              </>
            )
          }
          <div className={classNames(classes.paymentMethods, { [classes.hidden]: hiddenAltMethods })}>
            {
              ((showApplePay && injectedApplePay) || (showApplePay && isBaseModal)) && (
                <div style={{ marginTop: '16px' }} className={classes.buttonWrapper}>
                  <ApplePay
                    activeProduct={activeProduct}
                    requiredShippingAddress={requiredShippingAddress}
                    color={'black'}
                    className={classes.appleButton}
                    onApplePayClick={onApplePayClick}
                    onApplePaySubmit={onApplePaySubmit}
                  />
                </div>
              )
            }

            {
              <div className={classNames(classes.buttonWrapper, { [classes.hidden]: !(injectedApplePay || isBaseModal) })}>
                <div id={options?.applePayButtonParams?.containerId}></div>
              </div>
            }

            {
              <div className={classNames(classes.buttonWrapper, { [classes.hidden]: !(injectedGooglePay || isBaseModal) })}>
                <div id={options?.googlePayButtonParams?.containerId}></div>
              </div>
            }

            {
              (injectedDouble || (showGooglePay && isBaseModal)) && (
                <div className={classes.or}>
                  <p >{t(`${tKey}.or`)}</p>
                </div>
              )
            }

            {showApplePay && isBaseModal && (
              <div className={classes.or}>
                <p>{t(`${tKey}.or`)}</p>
              </div>
            )}

            {((showPayPal && injectedPayPal) || (showPayPal && isBaseModal)) && (
              <>
                <PayPal
                  product={activeProduct}
                  requiredShippingAddress={requiredShippingAddress}
                  onSubmit={onPayPalSubmit}
                />
                {
                  (isBaseModal) && (
                    <div className={classes.or}>
                      <p>{t(`${tKey}.or`)}</p>
                    </div>
                  )
                }
              </>
            )}
          </div>
        </div>

        <div className={classNames(formWrapStyle, { [classes.hidden]: !(injectedCard || isBaseModal) })}>
          <button
            type="button"
            onClick={handlePayWithCardClick}
            className={classes.payWithCard}
          >
            <IconCreditCard width={30} height={21} />
            <p className={classes.payWithCardText}>{t(`${tKey}.pay_with_card`)}</p>
            {(showApplePay || showPayPal || showGooglePay) && !injectedCard && (
              <div className={classes.arrowDownWrap}>
                <IconArrowDown
                  width={12}
                  height={6}
                  className={classNames(classes.iconArrow, {
                    [classes.iconArrowUp]: showCardForm,
                  })}
                />
              </div>
            )}
          </button>
          <div className={expandWrapStyle}>
            <ul className={classes.cardsList}>
              {_map(ICONS, (Icon, key) => (
                <li key={key} className={classes.cardItem}>
                  <Icon width="100%" height="100%" />
                </li>
              ))}
            </ul>
            <PaymentForm {...cardFormProps} />
          </div>
        </div>

        {!activeProduct.isOneTimePurchase && (
          <>
            {isShowSafeCheckout ? <PaymentSafe /> : null}
            {!showTermsAtPlans && (
              <PaymentDescription
                activeProduct={activeProduct}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

BasePaymentModalBody.propTypes = {
  activeProduct: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onApplePayClick: PropTypes.func.isRequired,
  onApplePaySubmit: PropTypes.func.isRequired,
  onGooglePayClick: PropTypes.func.isRequired,
  onGooglePaySubmit: PropTypes.func.isRequired,
  onPayPalSubmit: PropTypes.func.isRequired,
  onUserInfoChange: PropTypes.func.isRequired,
  allowApplePay: PropTypes.bool,
  options: PropTypes.object,
};

BasePaymentModalBody.defaultProps = {
  allowApplePay: false,
  options: null,
};

export default BasePaymentModalBody;
