import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { t } from '@web-solutions/module-localization';

import Checkbox from '../../checkbox';

import classes from './style.module.scss';

const tKey = 'core.agreement';

const PolicyCheckbox = ({ policyLinks, checkboxChecked }) => {
  const handleClick = (link) => () => {
    Analytics.trackEvent('sub_main', 'payment_policy_click', { link });
  };

  const [isChecked, setChecked] = useState(checkboxChecked);

  const handleChange = useCallback(() => {
    setChecked(!isChecked);
  }, [isChecked, setChecked]);

  const policyKeys = ['policy', 'privacy_policy', 'terms', 'billing_terms', 'and', 'money_back'];

  const links = {
    privacy_policy: policyLinks.privacy,
    terms: policyLinks.termsOfUse,
    billing_terms: policyLinks.billingTerms,
    money_back: policyLinks.moneyBack,
  };

  return (
    <div className={classes.confirmation}>
      <label className={classes.confirmation__label} htmlFor="checkbox">
        <Checkbox isChecked={isChecked} onChange={handleChange} />
        <p className={classNames(classes.text, classes.confirmation__text)}>
          {policyKeys.map((key) => {
            if (['privacy_policy', 'terms', 'billing_terms', 'money_back'].includes(key)) {
              return (
                <React.Fragment key={key}>
                  <a
                    className={classNames(classes.text, classes.confirmation__link)}
                    onClick={handleClick(links[key])}
                    href={links[key]}
                    target="_blank"
                  >
                    {t(`${tKey}.${key}`)}
                  </a>
                  <span> </span>
                </React.Fragment>
              );
            }
            return <span className={classes.confirmation__item} key={key}>{t(`${tKey}.${key}`)}</span>;
          })}
        </p>
      </label>
    </div>
  );
};

PolicyCheckbox.propTypes = {
  policyLinks: PropTypes.object.isRequired,
  checkboxChecked: PropTypes.bool,
};

PolicyCheckbox.defaultProps = {
  checkboxChecked: false,
};

export default PolicyCheckbox;
