import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import "./style.css";

import Analytics from "@web-solutions/module-analytics";
import { completeStepAndNavigate } from "@web-solutions/giantcrush/src/store/progress/actions";

export const QuizVideo = React.memo(({
                                     src = "",
                                     loop = true,
                                     autoContinue = false,
                                     interactionRequired = false,
                                     stepKey = '',
                                     onClick
                                   }) => {
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const [interacted, setInteracted]= useState(false);
  const [loaded, setLoaded] = useState(false);

  const navigate = () => {
    Analytics.trackEvent('step', 'completed', {
      id: stepKey
    });

    if (typeof onClick === 'function') {
      onClick();
      return;
    }

    dispatch(completeStepAndNavigate(stepKey, 1));
  };


  const handleLoad = () => {
    setLoaded(true);
    videoRef.current.loop = loop;
  };

  const handleProgress = (e) => {
    if (autoContinue && e.type === 'ended') {
      if (interactionRequired) {
        if (interacted) {
          navigate();
        }
        return;
      }

      navigate();
    }
  }
  
  const handleMouseDown = () => {
    setInteracted(true);
    if (!autoContinue) {
      navigate();
    }
  }

  return (
    <div className='quiz-video-container'>
      <video
        ref={videoRef}
        src={process.env.REACT_APP_PUBLIC_URL + src}
        autoPlay
        playsInline={true}
        loop
        className='quiz-video'
        onProgress={handleProgress}
        onEnded={handleProgress}
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
        onCanPlayThrough={handleLoad} />
      {!loaded ? (
        <>
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/QuizContent/Videos/preview.jpg`}
            className='quiz-video-preview' />
          <span className='quiz-video-preview-text'>...</span>
        </>
      ) : undefined}
    </div>
  );
});
