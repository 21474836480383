import "./style.css";

import React, { useEffect, useState } from "react";

const sensitivity = 0.5;
let startPositionX = 0;
let startPositionY = 0;
let deltaPositionX = 0;
let deltaPositionY = 0;
let endPositionX = 0;
let endPositionY = 0;

export const Crosshair = React.memo(({
                                         position = {x: 0, y: 0},
                                         collider = {width: 120, height: 120},
                                       }) => {

  const [currentPosition, setCurrentPosition] = useState({x: 0, y: 0});
  const [isVisible, setVisible] = useState(false);
  
  useEffect(() => {
    const constrainedPosition = constraintPositionInsideCollider(
      currentPosition.x,
      currentPosition.y);

    deltaPositionX = currentPosition.x;
    deltaPositionY = currentPosition.y;

    setCurrentPosition(constrainedPosition);
  }, [collider]);

  const handleTouchStart = (e) => {
    startPositionX = e.touches[0].screenX - endPositionX;
    startPositionY = e.touches[0].screenY - endPositionY;
    setVisible(true);
  };
  
  const handleMouseDown = (e) => {
    startPositionX = e.screenX - endPositionX;
    startPositionY = e.screenY - endPositionY;
    setVisible(true);
  };
  
  const constraintPositionInsideCollider = (x, y) => {
    const minX = -collider.width / 2;
    const maxX = collider.width / 2;
    const minY = -collider.height / 2;
    const maxY = collider.height / 2;
    
    return {
      x: x > maxX ? maxX : x < minX ? minX : x,
      y: y > maxY ? maxY : y < minY ? minY : y,
    }
  };

  const handleTouchMove = (e) => {
    const constrainedPosition = constraintPositionInsideCollider(
      (e.touches[0].screenX - startPositionX) * sensitivity,
      (e.touches[0].screenY - startPositionY) * sensitivity);

    deltaPositionX = constrainedPosition.x;
    deltaPositionY = constrainedPosition.y;

    setCurrentPosition(constrainedPosition);
  };

  const handleMouseMove = (e) => {
    if (!isVisible) {
      return;
    }

    const constrainedPosition = constraintPositionInsideCollider(
      (e.screenX - startPositionX) * sensitivity,
      (e.screenY - startPositionY) * sensitivity);

    deltaPositionX = constrainedPosition.x;
    deltaPositionY = constrainedPosition.y;

    setCurrentPosition(constrainedPosition);
  };
  const handleTouchEnd = () => {
    endPositionX = deltaPositionX;
    endPositionY = deltaPositionY;
    setVisible(false);
  };

  return (
    <div className='crosshair-interaction-container'
         onMouseDown={handleMouseDown}
         onMouseMove={handleMouseMove}
         onMouseUp={handleTouchEnd}
         onTouchStart={handleTouchStart}
         onTouchMove={handleTouchMove}
         onTouchEnd={handleTouchEnd}>
      <div className={`crosshair-collider ${isVisible ? 'visible' : ''}`}
           style={{
             width: `${collider.width}px`,
             height: `${collider.height}px`,
             marginLeft: position.x,
             marginTop: position.y
           }}>
        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/UI/Crosshair.png`}
             className='crosshair'
             style={{translate: `${currentPosition.x}px ${currentPosition.y}px`}}/>
      </div>
    </div>
  );
})