import DATA from "../../data/data.json";

import { createAction } from 'redux-actions';

import { COMPLETE_STEP, UPDATE_STEP, STEP_BACK, SET_PURCHASED } from './types';

export const completeStep = createAction(COMPLETE_STEP, (key, data) => ({ key, data, }));
export const updateStep = createAction(UPDATE_STEP, (key, data) => ({ key, data, }));

export const stepBack = createAction(STEP_BACK);

export const setPurchasedStatus = createAction(SET_PURCHASED);

export const completeStepAndNavigate = (key, data) => (dispatch, getState) => {
    const quizScreensOrder = getState().remoteConfig.quizScreensOrder;
    dispatch(completeStep(key, {
        stepData: data,
        totalSteps: quizScreensOrder.length
    }));
}

export const preloadAssetsAtIndex = (index, quizScreensOrder) => {
    const nextScreenId = quizScreensOrder[index];
    const nextScreenModel = DATA.quizScreens[nextScreenId];

    if (nextScreenId && nextScreenModel) {
        nextScreenModel.content?.map((entry) => {
            if (entry?.QuizImage) {
                preloadImage(entry.QuizImage);
            }
        })
    }
}

export const preloadImage = (src) => {
    const img = new Image();
    img.src = src;
}
