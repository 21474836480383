import React, { useRef, useState, useEffect } from 'react';
import "./style.css";

import { t } from "@web-solutions/module-localization";
import { useAnimationFrame } from "src/utils/rafHook";

export const QuizTutorial = React.memo(() => {
  const pathRef = useRef(null);
  const svgRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const svgRatio = { x: 1, y: 1 };

  useEffect(() => {
    const bcr = svgRef.current.getBoundingClientRect();
    svgRatio.x = bcr.width / svgRef.current.width.baseVal.value;
    svgRatio.y = bcr.height / svgRef.current.height.baseVal.value;
  }, [])

  const duration = 4000;
  let currentTime = 0;

  useAnimationFrame(deltaTime => {
    if (!pathRef?.current) return;
    
    const progress = currentTime / duration;
    const nextPoint = pathRef.current.getPointAtLength(
      pathRef.current.getTotalLength() * (1 - progress)
    );
    if (currentTime >= duration) {
      currentTime = 0;
    }
    currentTime += deltaTime;
    
    setPosition(() => ({
      x: (nextPoint.x * svgRatio.x),
      y: (nextPoint.y * svgRatio.y),
    }))
  })
  
  const handShift = {
    x: 15,
    y: 15,
  };

  return (
    <div className='quiz-tutorial'>
      <div className='quiz-tutorial-content'>
        <div className='quiz-tutorial-example'>
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/UI/SwipeExample.png`}
            className='quiz-tutorial-example-image' />
          <svg ref={svgRef} width="400" height="191" viewBox="0 0 400 191" fill="none" xmlns="http://www.w3.org/2000/svg"
            className='quiz-tutorial-example-svg'>
            <path ref={pathRef} d="M367 129C390.648 93.277 364.5 20.9999 306.514 19C261.496 17.4474 203.014 89.4999 203.014 89.4999C203.014 89.4999 170.654 145.546 133.014 161C81.8929 181.989 21.9582 158 23.0137 86.4999C23.5872 47.6565 70.4108 10.4055 112.514 20.9999C162.594 33.6015 209.014 93.277 209.014 93.277C209.014 93.277 296.5 235.5 367 129Z" stroke="black"/>
          </svg>
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/UI/HandSmall.png`}
            className='quiz-tutorial-example-hand'
            style={{ translate: `${position.x + handShift.x}px ${position.y + handShift.y}px` }} />
        </div>
        <span className='quiz-tutorial-text'>
           {t('tutorial.swipe_text')}
        </span>
      </div>
    </div>
  );
});
