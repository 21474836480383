// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".totxoMhZHLlAnGDxjZcp{text-decoration:none}.totxoMhZHLlAnGDxjZcp span{color:#fff;font-weight:600;text-decoration-line:underline;text-decoration-color:rgba(255,255,255,.7)}", "",{"version":3,"sources":["webpack://./../../packages/core/ui-elements/link-copy/style.module.scss"],"names":[],"mappings":"AAGA,sBACE,oBAAA,CACA,2BACE,UAAA,CACA,eAAA,CACA,8BAAA,CACA,0CAAA","sourcesContent":["$colorTheme: artpix;\n@import \"src/styles\";\n\n.root {\n  text-decoration: none;\n  span {\n    color: white;\n    font-weight: 600;\n    text-decoration-line: underline;\n    text-decoration-color: rgba(255, 255, 255, 0.7);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "totxoMhZHLlAnGDxjZcp"
};
export default ___CSS_LOADER_EXPORT___;
