import React from 'react';

import './style.scss';

export const QuizImage = React.memo((
  {
    src = '',
    className = '',
    hidden = false,
    onClick,
  },
) => {
  const fullSrc = process.env.REACT_APP_PUBLIC_URL + src;
  return (
    <div className={`quiz-image-container ${hidden ? 'hidden' : ''}`}
         onClick={onClick}>
      <img
        src={fullSrc}
        className={`quiz-image ${className}`}/>
      <img
        src={process.env.REACT_APP_PUBLIC_URL + '/assets/UI/Hidden.svg'}
        className='hidden-icon'/>
    </div>
  );
});
